import React, { useState, useEffect } from "react";

import "./styles.css";
// mensagens de erro
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

import api from "../../services/api";

import { useHistory } from "react-router-dom";
import "../../style/global.css";

import mask from "../../util/mask";

const convertDot = (value) => {
  let newPrice = value.toString();
  newPrice = newPrice.replace(".", ",");

  return newPrice;
};

function ListManager({ code }) {
  return (
    <div className="row-10 list mb-1">
      <div className="col-12 ">{code}</div>
    </div>
  );
}

export default ListManager;
