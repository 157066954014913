import React, { useState, useEffect } from "react";

// mensagens de erro
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

import { useHistory } from "react-router-dom";

import "../../style/global.css";
import "./style.css";

import api from "../../services/api";

// import logo from '../../assets/images/vitrin/Logo.png';
import logo from "../../assets/images/appito/Appito-logo.png";

import validate from "../../util/validate";

function Login() {
  let history = useHistory();

  //States
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(true);
  const [buttonAcess, setButtonAcess] = useState("Acessar");
  const [loaderIsTrue, setloaderIsTrue] = useState(false);

  function handleClickEye() {
    setShowPassword(!showPassword);
  }

  async function handleClickLogin() {
    setButtonAcess("");
    setloaderIsTrue(true);

    const validatespace = validate.validateSpace(email);

    if (validatespace) {
      setButtonAcess("Acessar conta");
      setloaderIsTrue(false);

      toast.error("e-mail não deve conter espaços");
      return;
    }

    const body = {
      email: email.toLowerCase().trim(),
      password: password.toLowerCase().trim(),
      rememberMe: true,
    };
    try {
      const response = await api.post("api/login/SupportLogin", body, {
        // const response = await api.post('/api/login/SupportLogin', body, {
        headers: {},
      });

      if (response.data.error === true) {
        toast.error(response.data.message);
        setButtonAcess("Acessar conta");
        setloaderIsTrue(false);
        return;
      }

      if (response.data.permissions.length === 0) {
        toast.error("Usuário sem permissões, contate suporte");
        localStorage.removeItem("token");
        history.push("/");
        setButtonAcess("Acessar conta");
        setloaderIsTrue(false);
        return;
      }

      const token = JSON.stringify(response.data);
      localStorage.setItem("token", token);

      history.push("/home");
    } catch (error) {
      setButtonAcess("Acessar conta");
      setloaderIsTrue(false);

      toast.error("Não foi possivel logar no momento, tente mais tarde!");
    }
  }

  useEffect(() => {}, []);

  return (
    <section
      className="fdb-block"
      style={{
        // backgroundColor: '#FF0000',
        marginTop: "160px",
      }}
    >
      <div className="container">
        <div className="row justify-content-center  ">
          <div
            className=" card col-10 col-md-5 col-lg-4 col-xl-3  text-center "
            style={{ backgroundColor: "#919191 " }}
          >
            <div className=" row justify-content-center mt-4">
              <div
                className=" col-12 "
                // style={{ backgroundColor: '#FF0000' }}
              >
                <img src={logo} alt="logo" width={200} />
              </div>
            </div>

            <div
              // style={{ backgroundColor: '#000' }}
              className="row justify-content-center"
            >
              <div
                className=" mt-1 col-12 col-sm-8 "
                // style={{ backgroundColor: '#fff' }}
              >
                <input
                  type="text"
                  className="form-control input-appito  scale-in-center "
                  placeholder="E-mail"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                />
              </div>

              <div
                className="mt-3 col-12 col-sm-8"
                // style={{ backgroundColor: '#fff000' }}
              >
                <input
                  type={showPassword ? "password" : "text"}
                  className="form-control mb-1 input-appito scale-in-center "
                  placeholder="Senha"
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                />
                {showPassword ? (
                  <i
                    className="bi bi-eye-slash-fill  position-relative"
                    style={{ color: "#E2E2E2  " }}
                    onClick={() => handleClickEye()}
                  />
                ) : (
                  <i
                    className="bi bi-eye-fill "
                    style={{ color: "#C4F54B" }}
                    onClick={() => handleClickEye()}
                  />
                )}
              </div>

              <div
                className="mt-3 col-sm-8 "
                // style={{ backgroundColor: '#fff000' }}
              >
                <button
                  className={`
                    btn button-primary-appito mb-4 col-6 col-sm-10  text-center scale-in-center
                    `}
                  type="button"
                  onClick={() => handleClickLogin()}
                >
                  <div className={` ${loaderIsTrue ? "loader" : ""}`}>
                    {buttonAcess}
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Login;
