import React, { useState, useEffect } from 'react';

// mensagens de erro
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';

import { useHistory } from 'react-router-dom';

import '../../style/global.css';
import Button from '../../components/Button';


import ListManager from '../../components/ListManager'

import { accessValidator } from '../../util/validationProfile';

function BaseScreen() {
  let history = useHistory();

  //States
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [password, setPassword] = useState('');
  const [roles, setRoles] = useState(['teste']);

  const [customLoader, setCustomLoader] = useState(true);

  const registrationCodes = [1958, 1962, 1970, 1994, 2002]
  
  function handleCancel() {
    history.push('/home');
  }

  const handleClickExampleLoader = () => {
    setCustomLoader(!customLoader);
  }

  const handleClickExampleSuccess = () => {
    toast.success("Um toast de sucesso")
  }

  const handleClickExampleWarn = () => {
    toast.warn("Um toast de warning")
  }

  const handleClickExampleInfo = () => {
    toast.info("Um toast de informação")
  }

  const handleClickExampleErro = () => {
    toast.error("Um toast de erro")
  }



  async function handleClickProfile() {
    const token = localStorage.getItem('token');

    const data = {
      firstName,
      lastName,
      password,
      newPassword,
    };

    try {
      // await api.put('/entrepreneur', data, {
      //   headers: { 'x-access-token': token },
      // });

      setPassword('');
      setNewPassword('');

      toast.success('Atualizado com sucesso.', {
        className: 'toast-success-PE',
      });
    } catch (error) {
      // alert(error.response.data.message);
      toast.error(error.response.data.message);
    }
  }

  function modify(data) {
    setFirstName(data.firstName);
    setLastName(data.lastName);
    setEmail(data.email);
  }

  async function getProfile() {
    const token = localStorage.getItem('token');
    try {
      // const response = await api.get('/entrepreneur', {
      //   headers: { 'x-access-token': token },
      // });
      // modify(response.data);
    } catch (error) {
      // alert(error.response.data.message);
      toast.error(error.response.data.message);
    }
  }



  const validate = () => {
    const verify = accessValidator('base');

    if(verify === false) {
      toast.info("Seu usuário não tem permissões para esse sistema, contate o suporte")
      history.push('/home');
    }
  }


  useEffect(() => {
    getProfile();
    validate();
  }, []);

  return (
    <section>
      <div className='container '>
        <div className='row justify-content-center '>
          <div className='col-12 col-md-12 col-lg-10 col-xl-10 text-center '>
            <div className='col'>
              <p className='text-title-Appito justify-content-center pt-4 tracking-in-expand '>
                Base screen
              </p>
            </div>


            <br/>
            <h1> Exemplo loader </h1>
            
            <br/>
            <div>
              <Button
                text='Exemplo loader'
                typeButton='primary'
                onClick={() => handleClickExampleLoader()}
              />
            </div>

            {/* <br/>
            <div className='divisor-green-1   ' />

            <br/>
            <div className='divisor-loader  ' /> */}

            <br/>
            <div className={customLoader === true ? 'divisor-loader' : 'divisor-green-1' } />

            {/* className={` ${copaScreen === 'copa' ? '' : 'd-none'}`} */}



            <br/>
            <br/>
            <div>
              <Button
                text='Botão primário'
                typeButton='primary'
                onClick={() => handleClickExampleLoader()}
              />
            </div>

            <div>
              <Button
                text='Botão secundário'
                typeButton='secundary'
                onClick={() => handleClickExampleLoader()}
              />
            </div>
            <br/>
            <div className='divisor-green-1   mb-4' />


            <p> Listagem códigos exemplo </p>


            <div className='row mt-3'>
              <small id='nome' className='form-text text-muted'>
                Exemplo 1
              </small>
              <div className='col'>
                <input
                  type='text'
                  className='form-control input-appito scale-in-center '
                  placeholder='Exemplo 1'
                  // value={name}
                  // onChange={(event) => setName(event.target.value)}
                />
              </div>
            </div>

            <div className='row mt-3'>
              <small id='nome' className='form-text text-muted'>
                Exemplo 2
              </small>
              <div className='col'>
                <input
                  type='text'
                  className='form-control input-appito-error scale-in-center '
                  placeholder='Exemplo 2'
                  // value={name}
                  // onChange={(event) => setName(event.target.value)}
                />
              </div>
            </div>



            <br/>
            <div className='divisor-green-1   mb-4' />

            <p> Listagem códigos exemplo </p>
            <div   className='col-4'>
              {
                registrationCodes.map((code) => (
                  <ListManager key={code} code={code}/>
                ))
              }
            </div>

            <div className='divisor-green-1  mb-4 mt-4 ' />

            <div style={{backgroundColor: '#000'}}>

              <div>
                <Button
                  text='Toast de sucesso'
                  typeButton='primary'
                  onClick={() => handleClickExampleSuccess()}
                  style = 'mb-2 col-6 col-md-4 col-lg-4 col-xl-4 text-center scale-in-center '
                />
              </div>
              <Button
                text='Toast de aviso'
                typeButton='primary'
                onClick={() => handleClickExampleWarn()}
                style = 'mb-2 col-6 col-md-4 col-lg-4 col-xl-4 text-center scale-in-center '

              />
              <Button
                text='Toast de informação'
                typeButton='primary'
                onClick={() => handleClickExampleInfo()}
                style = 'mb-2 col-6 col-md-4 col-lg-4 col-xl-4 text-center scale-in-center '

              />
              <Button
                text='Toast de erro'
                typeButton='primary'
                onClick={() => handleClickExampleErro()}
                style = 'mb-2 col-6 col-md-4 col-lg-4 col-xl-4 text-center scale-in-center '

              />
            </div>

            <br/>
            <div className='divisor-green-1  mb-4 mt-4 ' />
          </div>
        </div>
      </div>
    </section>
  );
}

export default BaseScreen;
