import React from "react";
import { ToastContainer } from "react-toastify";

import Routes from "./routes/index";

function App() {
  return (
    <div
    // style={{ backgroundColor: '#919191' }}
    >
      <ToastContainer autoClose={3000} />
      <Routes />
    </div>
  );
}

export default App;
