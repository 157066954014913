import axios from "axios";

let baseURL = "";

const Environment = process.env.REACT_APP_ENVIRONMENT.trim();

switch (Environment) {
  case "production":
    baseURL = process.env.REACT_APP_PRD;
    console.log(baseURL);
    break;
  case "development":
    baseURL = process.env.REACT_APP_QA;
    console.log(process.env.REACT_APP_QA);
    console.log(baseURL);
    break;
  default:
    baseURL = process.env.REACT_APP_LOCAL;
    break;
}

const api = axios.create({
  baseURL: baseURL,
});

export default api;
