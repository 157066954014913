import React, { useState, useEffect } from "react";

import "./style.css";

import iconWhatsapp from "../../assets/icons/booking-contract/whatsapp.svg";
import iconWhatsappRed from "../../assets/icons/booking-contract/whatsapp_red.svg";
import iconMail from "../../assets/icons/booking-contract/mail.svg";
import iconMailRed from "../../assets/icons/booking-contract/mail_red.svg";
function CouponList({ coupon, onClickDetails }) {
  useEffect(() => {}, []);

  return (
    <>
      <div className="coupon-component" onClick={() => onClickDetails()}>
        <div className="coupon-code-with-info">
          <div className="text-start d-flex">
            <div className="text-truncate ">{coupon?.code}</div>
          </div>
          <span className="coupon-info ">{coupon?.category}</span>
        </div>

        <div className="coupon-value">
          <p className="col-12  text-start">
            <div className="text-truncate">{coupon?.value}</div>
          </p>
        </div>
        <div className="coupon-userType">
          <p className="col-12 text-start">
            <div className="text-truncate">{coupon?.userType}</div>
          </p>
        </div>

        <div className="coupon-bookingType">
          <p className="col-12 text-start">
            <div className="text-truncate">{coupon?.bookingType}</div>
          </p>
        </div>

        <div className="coupon-spaceType-with-info">
          <p className="col-12 text-start">
            <div className="text-truncate">{coupon?.spaceType}</div>
            <span className="coupon-info">{coupon?.arena}</span>
          </p>
        </div>

        {/* BADGES STATUS */}

        <div className="coupon-status">
          <div className="col text-center d-flex justify-content-start">
            <h5
              className={`text-center ${
                coupon?.status === "E"
                  ? "coupon-expirado componentBadge "
                  : "d-none"
              } `}
            >
              Expirado
            </h5>

            <h5
              className={`text-center ${
                coupon?.status === "C"
                  ? "coupon-cancelado componentBadge "
                  : "d-none"
              } `}
            >
              Cancelado
            </h5>

            <h5
              className={`text-center ${
                coupon?.status === "D"
                  ? "coupon-disponivel componentBadge"
                  : "d-none"
              } `}
            >
              Disponível
            </h5>

            <h5
              className={`text-center ${
                coupon?.status === "A"
                  ? "coupon-agendado componentBadge "
                  : "d-none"
              } `}
            >
              Agendado
            </h5>
          </div>
        </div>

        {/* FIM BADGES STATUS */}
      </div>
      <div className="coupon-divisor-grey" />
    </>
  );
}

export default CouponList;
