import React, { useState, useEffect } from 'react';

// mensagens de erro
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';

import { useHistory } from 'react-router-dom';

import '../../style/global.css';
import Button from '../../components/Button/';

import api from '../../services/api';

function Profile() {
  let history = useHistory();

  //States
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [password, setPassword] = useState('');
  const [roles, setRoles] = useState(['teste']);
  const [showPassword, setShowPassword] = useState(true);

  function handleCancel() {
    history.push('/home');
  }

  function handleClickEye() {
    setShowPassword(!showPassword);
  }

  async function handleClickProfile() {
    const token = localStorage.getItem('token');

    const data = {
      firstName,
      lastName,
      password,
      newPassword,
    };

    try {
      // await api.put('/entrepreneur', data, {
      //   headers: { 'x-access-token': token },
      // });

      setPassword('');
      setNewPassword('');

      toast.success('Atualizado com sucesso.', {
        className: 'toast-success-PE',
      });
    } catch (error) {
      // alert(error.response.data.message);
      toast.error(error.response.data.message);
    }
  }

  function modify(data) {
    setFirstName(data.firstName);
    setLastName(data.lastName);
    setEmail(data.email);
  }

  async function getProfile() {
    const token = localStorage.getItem('token');
    try {
      // const response = await api.get('/entrepreneur', {
      //   headers: { 'x-access-token': token },
      // });
      // modify(response.data);
    } catch (error) {
      // alert(error.response.data.message);
      toast.error(error.response.data.message);
    }
  }

  useEffect(() => {
    getProfile();
  }, []);

  return (
    <section>
      <div className='container '>
        <div className='row justify-content-center '>
          <div className='col-12 col-md-12 col-lg-10 col-xl-10 text-center '>
            <div className='col'>
              <p className='text-title-Appito justify-content-center pt-4 tracking-in-expand '>
                Meu Perfil
              </p>
            </div>

            <div className='row mt-3'>
              <small id='nome' className='form-text text-muted'>
                Nome
              </small>
              <div className='col'>
                <input
                  type='text'
                  className='form-control input-appito scale-in-center '
                  placeholder='Nome'
                  value={firstName}
                  onChange={(event) => setFirstName(event.target.value)}
                />
              </div>
            </div>

            <div className='row mt-3'>
              <small id='sobrenome' className='form-text text-muted'>
                Sobrenome
              </small>
              <div className='col'>
                <input
                  type='text'
                  className='form-control input-appito scale-in-center '
                  placeholder='Sobrenome'
                  value={lastName}
                  onChange={(event) => setLastName(event.target.value)}
                />
              </div>
            </div>

            <div className='row mt-3'>
              <small id='email' className='form-text text-muted'>
                Email
              </small>
              <div className='col'>
                <input
                  disabled
                  type='text'
                  className='form-control input-appito  scale-in-center'
                  placeholder='Email'
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                />
              </div>
            </div>

            <div className='col'>
              <p className='text-subTitle-Appito justify-content-center pt-4 tracking-in-expand '>
                Alterar senha
              </p>
            </div>

            <div className='row'>
              <div className='col'>
                <input
                  type={showPassword ? 'password' : 'text'}
                  className='form-control mb-1 input-appito scale-in-center '
                  placeholder='Senha atual'
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                />
              </div>
            </div>

            <div className='row mt-4'>
              <div className='col'>
                <input
                  type={showPassword ? 'password' : 'text'}
                  className='form-control mb-1 input-appito scale-in-center '
                  placeholder='Nova senha'
                  value={newPassword}
                  onChange={(event) => setNewPassword(event.target.value)}
                />
                {showPassword ? (
                  <i
                    className='bi bi-eye-slash-fill  position-relative'
                    style={{ color: '#d8bfd8' }}
                    onClick={() => handleClickEye()}
                  />
                ) : (
                  <i
                    className='bi bi-eye-fill '
                    style={{ color: '#0a169a' }}
                    onClick={() => handleClickEye()}
                  />
                )}
              </div>
            </div>

            <div>
              <Button
                text='Atualizar'
                typeButton='primary'
                onClick={() => handleClickProfile()}
              />
            </div>

            <div className=' mb-2 mt-2 divisor-green-1' />

            <div>
              <Button
                text='Voltar'
                typeButton='secundary'
                onClick={() => handleCancel()}
                style='btn-sm col-3 mb-4 scale-in-center'
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Profile;
