import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

//Commented due to error on react 17 (react 17 is needed due to problem with used libs)
//const root = ReactDOM.createRoot(document.getElementById('root'));
const root = ReactDOM.render(<App />, document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
