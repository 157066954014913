/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import "../../style/global.css";
import "./style.css";

// mensagens de erro
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

import api from "../../services/api";

import logo from "../../assets/images/appito/logo-appito-white@2x.png";

import { accessValidator, accessList } from "../../util/validationProfile";

function Header({ children }) {
  //States
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [business, setBusiness] = useState("");
  const [user, setUser] = useState({});
  const [showHeader, setShowHeader] = useState(true);

  const [smsScreen, setSmsScreen] = useState("");
  const [homeScreen, setHomeScreen] = useState("");
  const [monthlyBillingScreen, setMonthlyBillingScreen] = useState("");
  const [billingScreen, setBillingScreen] = useState("");
  const [baseScreen, setBaseScreen] = useState("");
  const [smsSchoolScreen, setSmsSchoolScreen] = useState("");
  const [copaScreen, setCopaScreen] = useState("");
  const [couponScreen, setCouponScreen] = useState("");

  let screens = [];
  let history = useHistory();

  function openNav() {
    setIsMenuOpen(true);
  }

  function closeNav() {
    setIsMenuOpen(false);
  }

  function handleLogout() {
    localStorage.removeItem("token");
    history.push("/");
  }

  function handleClickCopa() {
    history.push("/copa");
    closeNav();
  }

  function handleClickMonthlyBilling() {
    history.push("/MonthlyBilling");
    closeNav();
  }

  function handleClickBilling() {
    history.push("/Billing");
    closeNav();
  }

  function handleClickBase() {
    history.push("/base");
    closeNav();
  }

  function handleClickSms() {
    history.push("/sms");
    closeNav();
  }

  function handleClickCoupons() {
    history.push("/coupons");
    closeNav();
  }

  function handleClickSchool() {
    history.push("/school");
    closeNav();
  }

  function handleClickHome() {
    history.push("/home");
    closeNav();
  }

  const permissionScreen = () => {
    const screens = accessList();

    const homeScreen = screens.find((home) => home === "home");
    setHomeScreen(homeScreen);

    const monthlyBillingScreen = screens.find(
      (monthlyBilling) => monthlyBilling === "monthlyBilling"
    );
    setMonthlyBillingScreen(monthlyBillingScreen);

    const billingScreen = screens.find((billing) => billing === "billing");
    setBillingScreen(billingScreen);

    const smsScreen = screens.find((sms) => sms === "sms");
    setSmsScreen(smsScreen);

    const smsSchool = screens.find((school) => school === "school");
    setSmsSchoolScreen(smsSchool);

    const smsBase = screens.find((base) => base === "base");
    setBaseScreen(smsBase);

    const copaScreen = screens.find((base) => base === "copa");
    setCopaScreen(copaScreen);

    const couponScreen = screens.find((base) => base === "coupon");
    setCouponScreen(couponScreen);
  };

  useEffect(() => {
    permissionScreen();
  }, []);

  return (
    <>
      <header
        className={`
          ${showHeader === false ? "d-none" : ""} `}
        // TODO - FIXAR MENU NO TOPO
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          zIndex: 1,
          width: "100%",
          backgroundColor: "#000",
        }}
      >
        <nav className="navbar mx-1">
          <span
            className="text-title-Appito col-2 col-sm-2 col-md-2 "
            style={{ fontSize: "30px", cursor: "pointer" }}
            onClick={() => openNav()}
          >
            {/* &#9776; */}
            <i
              style={{
                paddingLeft: "15px",
                color: "#fff",
              }}
              className="bi bi-list"
            ></i>
            {/* <i className='bi bi-list'></i> */}
          </span>

          {/* <div
            style={{
              color: '#fff',
              backgroundColor: '#000',
            }}
            className='text-title-Appito col-6 col-sm-8 col-md-8  d-flex justify-content-center tracking-in-expand '
          >
            <a>Suporte</a>
          </div> */}

          <div
            id="mySidenav"
            className={`sidenav ${
              isMenuOpen ? "openPrincipalMenu" : "closedPrincipalMenu"
            }`}
          >
            <p className="closebtn" onClick={() => closeNav()}>
              {/* &times; */}
              <i className="bi bi-chevron-double-left"></i>
            </p>
            <p className="" onClick={() => handleClickHome()}>
              Inicio
            </p>

            <div
              className={` ${
                monthlyBillingScreen === "monthlyBilling" ? "" : "d-none"
              }`}
              onClick={() => handleClickMonthlyBilling()}
            >
              <p>Cobranças Mensalistas</p>
            </div>

            <div
              className={` ${billingScreen === "billing" ? "" : "d-none"}`}
              onClick={() => handleClickBilling()}
            >
              <p>Pagamentos</p>
            </div>
            <div
              className={` ${copaScreen === "copa" ? "" : "d-none"}`}
              onClick={() => handleClickCopa()}
            >
              <p>Copa</p>
            </div>

            <div
              className={` ${smsScreen === "sms" ? "" : "d-none"}`}
              onClick={() => handleClickSms()}
            >
              <p>SMS</p>
            </div>

            <div
              className={` ${couponScreen === "coupon" ? "" : "d-none"}`}
              onClick={() => handleClickCoupons()}
            >
              <p>Cupons</p>
            </div>

            {/* <div
              className={` ${smsSchoolScreen === 'school' ? '' : 'd-none'}`}
              onClick={() => handleClickSchool()}
            >
              <p>
                Escolinha
              </p>
            </div> */}

            <div
              className={` ${baseScreen === "base" ? "" : "d-none"}`}
              onClick={() => handleClickBase()}
            >
              <p>Tela base</p>
            </div>

            <p className="" onClick={() => handleLogout()}>
              Sair
            </p>
          </div>

          <div
            onClick={() => handleClickHome()}
            className="btn col-4 col-sm-2 col-xl-1 "
          >
            <img src={logo} alt="logo" />
          </div>

          {/* <div
            onClick={() => handleClickHome()}
            className='btn col-3  d-block d-lg-none d-xl-none p-0 '
          >
            <img src={logo} width='100%' alt='logo' />
          </div> */}
        </nav>
      </header>
      {/* <div className='fixedCustom'>{children}</div> */}
      <div
        style={{
          marginTop: 60,
          // overflow: "hidden",
          width: "100%",
          height: "100%",
        }}
      >
        {children}
      </div>
    </>
  );
}

export default Header;
