import React, { useState, useEffect } from "react";

// mensagens de erro
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

import { useHistory } from "react-router-dom";

import "../../style/global.css";
import Button from "../../components/Button";

import api from "../../services/api";

import mask from "../../util/mask";

import method from "../../services/apiLocal";

import { accessValidator } from "../../util/validationProfile";

function Billing() {
  let history = useHistory();

  //States
  const [customLoader, setCustomLoader] = useState(false);

  const [documentUser, setDocumentUser] = useState("");
  const [documentValidator, setDocumentValidator] = useState(false);

  const [name, setName] = useState("");
  const [nameValidator, setNameValidator] = useState(false);

  const [email, setEmail] = useState("");
  const [emailValidator, setEmailValidator] = useState(false);

  const [phone, setPhone] = useState("");
  const [phoneValidator, setPhoneValidator] = useState(false);

  const [countryNumber, setCountryNumber] = useState("");
  const [countryNumberValidator, setCountryNumberValidator] = useState(false);
  const [countries, setCountries] = useState([]);

  const [price, setPrice] = useState();
  const [priceValidator, setPriceValidator] = useState(false);

  const [dueDate, setDueDate] = useState("");
  const [dueDateValidator, setDueDateValidator] = useState(false);

  const [cep, setCep] = useState("");
  const [cepValidator, setCepValidator] = useState(false);

  const [logradouro, setLogradouro] = useState("");
  const [logradouroValidator, setLogradouroValidator] = useState(false);

  const [addressComplement, setAddressComplement] = useState("");
  const [addressComplementValidator, setAddressComplementValidator] =
    useState(false);

  const [bairro, setBairro] = useState("");
  const [bairroValidator, setBairroValidator] = useState(false);

  const [localidade, setLocalidade] = useState("");
  const [localidadeValidator, setLocalidadeValidator] = useState(false);

  const [uf, setUf] = useState("");
  const [ufValidator, setUfValidator] = useState(false);

  const [numero, setNumero] = useState("");
  const [numeroValidator, setNumeroValidator] = useState(false);

  const [enderecoCompleto, setEnderecoCompleto] = useState(false);

  const [returnValidatorId, setReturnValidatorId] = useState(false);
  const [returnValidatorCodigo, setReturnValidatorCodigo] = useState(false);
  const [returnValidatorQrCode, setReturnValidatorQrCode] = useState(false);
  const [returnValidatorLink, setReturnValidatorLink] = useState(false);

  const [link, setLink] = useState("");
  const [codigo, setCodigo] = useState("");
  const [qrCode, setQrCode] = useState("");
  const [returnId, setReturnId] = useState("");

  const [banks, setBanks] = useState([]);
  const [bankID, setBankID] = useState("");
  const [bankIDValidator, setBankIDValidator] = useState(false);

  const [formOfPayments, setFormOfPayments] = useState([]);
  const [formOfPayment, setFormOfPayment] = useState("");
  const [formOfPaymentValidator, setFormOfPaymentValidator] = useState(false);

  const [showInstallment, setshowInstallment] = useState(false);
  const [installments, setInstallments] = useState(1);

  const [arenas, setArenas] = useState([]);
  const [arenasValidator, setArenasValidator] = useState(false);
  const [arenaId, setArenaId] = useState();

  const [sources, setSources] = useState([]);
  const [source, setSource] = useState("");

  const handleClickLoader = (loader = false) => {
    setCustomLoader(loader);
  };

  const removeCharsPhone = (characteres) => {
    let newPhone = mask.maskPhone(characteres);
    setPhone(newPhone);
  };

  const removeCharsDocument = (characteres) => {
    let newCPF = mask.cpfcnpj(characteres);
    setDocumentUser(newCPF);
  };

  const removeCharsPrice = (characteres) => {
    let newValue = mask.convertMoneyToBrazil(characteres);
    setPrice(newValue);
  };

  async function handleClickCep(cep) {
    try {
      const token = localStorage.getItem("token");
      const object = JSON.parse(token);
      let accessToken = "";
      if (object.accessToken) {
        accessToken = object.accessToken;
      }

      const response = await api.get(`api/utils/ZipCode/${cep}`, {
        headers: { Authorization: "Bearer " + accessToken },
      });

      if (response.data.error === true) {
        toast.error("CEP não encontrado");
        setLogradouro("");
        setBairro("");
        setNumero("");
        setLocalidade("");
        setUf("");
        return;
      }

      setLogradouro(response.data.address);
      setLogradouroValidator(false);
      setBairro(response.data.neighborhood);
      setBairroValidator(false);
      setNumero("");
      setNumeroValidator(false);
      setLocalidade(response.data.city);
      setLocalidadeValidator(false);
      setUf(response.data.state);

      setUfValidator(false);
      setEnderecoCompleto(false);
    } catch (error) {
      toast.error("Erro ao encontrar CEP, verifique o seu endereço.");
      setLogradouro("");
      setBairro("");
      setNumero("");
      setLocalidade("");
      setUf("");
    }
  }

  const makeMaskCEP = async (cep) => {
    let newCep = mask.maskCEP(cep);

    setCep(newCep);

    if (cep.length >= 8) {
      await handleClickCep(cep);
    }
  };

  const validateEmail = (email) => {
    let validate1 = email.includes("@");
    let validate2 = email.includes(".");

    if (validate1 === false || validate2 === false) {
      return false;
    }
    return true;
  };

  async function dataValidator() {
    let validatorImpedimento = true;

    let ufValidatorAUX = false;
    let numeroValidatorAUX = false;
    let localidadeValidatorAUX = false;
    let logradouroValidatorAUX = false;
    let cepValidatorAUX = false;
    let bairroValidatorAUX = false;

    if (name === "") {
      setNameValidator(true);
      validatorImpedimento = false;
    } else {
      const nameParts = name.trim().split(" ");
      const firstName = nameParts[0]; // Primeiro nome
      const lastName = nameParts[nameParts.length - 1]; // Último nome

      if (nameParts.length > 1) {
        if (name.length > 64) {
          setNameValidator(true);
          validatorImpedimento = false;
          toast.error("O nome deve ter máximo de 64 caracteres.");
        } else {
          if (lastName != "") {
            setNameValidator(false);
          } else {
            setNameValidator(true);
            validatorImpedimento = false;
            toast.error("Preencha nome completo");
          }
        }
      } else {
        setNameValidator(true);
        validatorImpedimento = false;
        toast.error("Preencha nome completo");
      }
    }

    if (email === "") {
      setEmailValidator(true);
      validatorImpedimento = false;
    } else {
      const mailBoolean = await validateEmail(email);
      if (mailBoolean === false) {
        setEmailValidator(true);
        toast.error("Preencha um e-mail válido");
      } else {
        setEmailValidator(false);
      }
    }

    if (documentUser === "") {
      setDocumentValidator(true);
      validatorImpedimento = false;
    } else {
      setDocumentValidator(false);
    }

    if (phone === "") {
      setPhoneValidator(true);
      setCountryNumberValidator(true);
      validatorImpedimento = false;
    } else {
      setPhoneValidator(false);
      setCountryNumberValidator(false);
    }

    if (price === 0 || price === "") {
      setPriceValidator(true);
      validatorImpedimento = false;
    } else {
      setPriceValidator(false);
    }

    if (dueDate === "") {
      setDueDateValidator(true);
      validatorImpedimento = false;
    } else {
      const actualDate = mask.formatDate(new Date());

      if (dueDate < actualDate) {
        setDueDateValidator(true);
        toast.error("A data NÃO deve ser anterior ao dia de hoje");
        validatorImpedimento = false;
      } else {
        setDueDateValidator(false);
      }
    }

    if (cep === "") {
      setCepValidator(true);
      setEnderecoCompleto(true);
      validatorImpedimento = false;
      cepValidatorAUX = true;
    } else {
      setCepValidator(false);
      cepValidatorAUX = false;
    }

    if (logradouro === "") {
      setLogradouroValidator(true);
      setEnderecoCompleto(true);
      validatorImpedimento = false;
      logradouroValidatorAUX = true;
    } else {
      setLogradouroValidator(false);
      logradouroValidatorAUX = false;
    }

    if (numero === "") {
      setNumeroValidator(true);
      setEnderecoCompleto(true);
      validatorImpedimento = false;
      numeroValidatorAUX = true;
    } else {
      setNumeroValidator(false);
      numeroValidatorAUX = false;
    }

    if (bairro === "") {
      setBairroValidator(true);
      setEnderecoCompleto(true);
      validatorImpedimento = false;
      bairroValidatorAUX = true;
    } else {
      setBairroValidator(false);
      bairroValidatorAUX = false;
    }

    if (localidade === "") {
      setLocalidadeValidator(true);
      setEnderecoCompleto(true);
      validatorImpedimento = false;
      localidadeValidatorAUX = true;
    } else {
      setLocalidadeValidator(false);
      localidadeValidatorAUX = false;
    }

    if (uf === "") {
      setUfValidator(true);
      setEnderecoCompleto(true);
      validatorImpedimento = false;
      ufValidatorAUX = true;
    } else {
      setUfValidator(false);
      ufValidatorAUX = false;
    }

    const array = [
      ufValidatorAUX,
      numeroValidatorAUX,
      localidadeValidatorAUX,
      logradouroValidatorAUX,
      cepValidatorAUX,
      bairroValidatorAUX,
    ];
    const found = array.find((element) => element === true);
    if (found === undefined) {
      setEnderecoCompleto(false);
    } else {
      setEnderecoCompleto(true);
    }

    return validatorImpedimento;
  }

  async function handleClickGeneratePayment() {
    const token = localStorage.getItem("token");
    const object = JSON.parse(token);

    let validador = true;
    validador = await dataValidator();
    if (validador === false) {
      toast.error("Campos em vermelho precisam ser preenchidos corretamente");
      return;
    } else {
      try {
        setCodigo("");
        setLink("");
        setQrCode("");
        setReturnValidatorId(false);
        setReturnValidatorCodigo(false);
        setReturnValidatorLink(false);
        setReturnValidatorQrCode(false);

        const newPhone =
          countryNumber +
          phone.replace("(", "").replace(")", "").replace("-", "").trim();
        const newZipcode = cep.replace(".", "").replace("-", "");
        const newDocument = documentUser
          .replace(/\.|\-/g, "")
          .replace(/[//"]/g, "");
        let newPrice = 0;

        if (price > "0") {
          newPrice = price.replace(/\./g, "").replace(/,/g, ".");

          newPrice = parseFloat(newPrice);
        }

        const nameParts = name.trim().split(" ");
        const firstName = nameParts[0];
        const lastName = nameParts.slice(1).join(" ");

        let installmentCustom = parseInt(installments);

        let payment = {
          ArenaID: arenaId,
          BankID: bankID,
          SourceID: null, // OBS: mesma coisa que chargeID, vai vazio no suporte
          Source: "G",
          FirstName: firstName,
          LastName: lastName,
          Document: null,
          Cpf: null,
          Cnpj: null,
          MobilePhone: newPhone.trim(),
          Email: email.trim(),
          Address: logradouro.trim(),
          AddressNumber: numero,
          AddressComplement: addressComplement.trim(),
          Neighborhood: bairro.trim(),
          ZipCode: newZipcode,
          City: localidade.trim(),
          State: uf,
          Country: "br",
          Value: newPrice,
          Description: source,
          Type: null,
          CardType: null,
          Installments: installmentCustom,
          FormOfPayment: formOfPayment,
          DueDate: dueDate + "T23:59:59Z",
          returnUrl: null,
          CardToken: null,
        };

        if (newDocument.length === 14) {
          payment = { ...payment, Cnpj: newDocument };
        } else {
          payment = { ...payment, Cpf: newDocument };
        }

        let accessToken = "";
        if (object.accessToken) {
          accessToken = object.accessToken;
        }

        var message = "";
        switch (formOfPayment) {
          case "P":
            message = "pix";
            break;
          case "B":
            message = "boleto";
            break;
          default:
            message = "link";
            break;
        }

        toast.info("Gerando " + message + ", Aguarde...");
        handleClickLoader(true);

        const response = await api.post("api/suporte/CreatePayment", payment, {
          headers: { Authorization: "Bearer " + accessToken },
        });

        if (
          response.data?.isValidDocument === false &&
          response.data?.error === false
        ) {
          setDocumentValidator(true);
          handleClickLoader();
          toast.error("CPF/CNPJ inválido");
          return;
        } else {
          setDocumentValidator(false);
        }

        if (response.data?.transactionID) {
          setReturnId(response.data.transactionID);

          if (response.data?.barcode) {
            setCodigo(response.data.barcode);
            setReturnValidatorId(true);
            setReturnValidatorCodigo(true);
          }

          if (response.data?.link) {
            setLink(response.data.link);
            setReturnValidatorId(true);
            setReturnValidatorLink(true);
          }

          if (response.data?.qrCode) {
            setReturnValidatorQrCode(true);
            setReturnValidatorId(true);
            setQrCode(response.data.qrCode);
          }

          toast.success(message + " gerado com sucesso");
        } else {
          if (
            response.data?.error === false &&
            response.data?.message.length > 0
          ) {
            toast.error(response.data?.message);
          } else {
            toast.error("Erro ao gerar pagamento, contate o suporte");
          }
        }

        handleClickLoader();
      } catch (error) {
        var message = "";
        switch (formOfPayment) {
          case "P":
            message = "pix";
            break;
          case "B":
            message = "boleto";
            break;
          default:
            message = "link";
            break;
        }

        console.log("error ", error);
        toast.error("Erro ao gerar " + message);
        handleClickLoader();
      }
    }
  }

  const handleClickClipboardId = () => {
    // navigator.clipboard.writeText(returnId);

    // tentativa 2
    const textarea = document.createElement("textarea");
    document.body.appendChild(textarea);
    textarea.value = returnId;
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);

    toast.success("Id copiado com sucesso");
  };

  const handleClickClipboardLink = () => {
    // navigator.clipboard.writeText(link);

    // tentativa 2
    const textarea = document.createElement("textarea");
    document.body.appendChild(textarea);
    textarea.value = link;
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);

    toast.success("Link copiado com sucesso");
  };

  const handleClickClipboarCode = () => {
    // navigator.clipboard.writeText(codigo);

    // tentativa 2
    const textarea = document.createElement("textarea");
    document.body.appendChild(textarea);
    textarea.value = codigo;
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);

    toast.success("Código copiado com sucesso");
  };

  const handleClickClipboarQRCode = () => {
    // navigator.clipboard.writeText(codigo);

    // tentativa 2
    const textarea = document.createElement("textarea");
    document.body.appendChild(textarea);
    textarea.value = qrCode;
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);

    toast.success("Código copiado com sucesso");
  };

  const handleClickClipBoth = () => {
    const message = `id: ${returnId}

Codigo: ${codigo} 

Link: ${link}

Obrigado por comprar com Appito
          `;
    navigator.clipboard.writeText(message);
    toast.success("Código copiado com sucesso");
  };

  const validate = () => {
    const verify = accessValidator("billing");

    if (verify === false) {
      toast.info(
        "Seu usuário não tem permissões para esse sistema, contate o suporte"
      );
      history.push("/home");
    }
  };

  const getArena = async () => {
    try {
      const token = localStorage.getItem("token");
      const object = JSON.parse(token);
      let accessToken = "";
      if (object.accessToken) {
        accessToken = object.accessToken;
      }
      let userID = "";
      if (object.userID) {
        userID = object.userID;
      }

      const response = await api.get(`api/event/GetArenas/${userID}`, {
        headers: { Authorization: "Bearer " + accessToken },
      });

      setArenas(response.data.list);
      setArenaId(response.data.list[0].arenaID);
    } catch (error) {
      console.log("getArena ", error);
      setArenas([]);
    }
  };

  const getSources = async () => {
    try {
      const token = localStorage.getItem("token");
      const object = JSON.parse(token);
      let accessToken = "";
      if (object.accessToken) {
        accessToken = object.accessToken;
      }
      let userID = "";
      if (object.userID) {
        userID = object.userID;
      }

      const response = await api.get(`api/payments/GetSources/${userID}`, {
        headers: { Authorization: "Bearer " + accessToken },
      });

      setSource(response.data.list[0].source);
      setSources(response.data.list);
    } catch (error) {
      console.log("getSources ", error);
      setSources([]);
    }
  };

  const getBanks = async () => {
    const token = localStorage.getItem("token");
    const object = JSON.parse(token);
    let accessToken = "";
    if (object.accessToken) {
      accessToken = object.accessToken;
    }
    let userID = "";
    if (object.userID) {
      userID = object.userID;
    }

    try {
      const response = await api.get(`api/payments/GetBanks/${userID}`, {
        headers: { Authorization: "Bearer " + accessToken },
      });

      setBanks(response.data.list);
      setBankID(response.data.list[0].value);
    } catch (error) {
      console.log("getBanks ", error);
      setBanks([]);
    }
  };

  const getFormOfPayments = async () => {
    const token = localStorage.getItem("token");
    const object = JSON.parse(token);
    let accessToken = "";
    if (object.accessToken) {
      accessToken = object.accessToken;
    }
    let userID = "";
    if (object.userID) {
      userID = object.userID;
    }

    try {
      const response = await api.get(`api/payments/FormOfPayments/${userID}`, {
        headers: { Authorization: "Bearer " + accessToken },
      });

      formOfPaymentCustom(response.data.list[0].value);
      setFormOfPayments(response.data.list);
    } catch (error) {
      console.log("getFormOfPayments ", error);
      setFormOfPayments([]);
    }
  };

  const getCountries = async () => {
    try {
      const token = localStorage.getItem("token");
      const object = JSON.parse(token);
      let accessToken = "";
      if (object.accessToken) {
        accessToken = object.accessToken;
      }

      const response = await api.get(`api/utils/GetCountry/PT`, {
        headers: { Authorization: "Bearer " + accessToken },
      });

      let lista = response.data.list;
      const index = lista.findIndex((item) => item.ddi === "+55"); // Encontre o índice do objeto com ddi = "+55"

      if (index !== -1) {
        // Se o índice for diferente de -1 (ou seja, encontrado), mova-o para a primeira posição
        const item = lista.splice(index, 1)[0]; // Remove o item da lista
        lista.unshift(item); // Adiciona o item no início da lista
      }

      setCountries(lista);
      setCountryNumber("+55");
    } catch (error) {
      console.log("getCountries ", error);
      setCountries([]);
    }
  };

  const handlePaste = (event) => {
    event.preventDefault(); // Impede a ação padrão de colar
    toast.error("Não é permitido Ctrl+V");
  };

  const formOfPaymentCustom = (formOfPayment) => {
    if (formOfPayment === "L" || formOfPayment === "G") {
      setshowInstallment(true);
    } else {
      setshowInstallment(false);
      setInstallments(1);
    }

    setFormOfPayment(formOfPayment);
  };

  useEffect(() => {
    setshowInstallment(false);
    setUf("SP");
    getCountries();
    validate();
    getArena();
    getBanks();
    getSources();
    getFormOfPayments();
  }, []);

  return (
    <section>
      <div className="container ">
        <div className="row justify-content-center ">
          <div className="col-12 col-md-12 col-lg-10 col-xl-10 text-center ">
            <div className="col">
              <p className="text-title-Appito justify-content-center pt-4 tracking-in-expand ">
                Gerador de Pagamento
              </p>
            </div>

            <div className="row mt-2">
              <div className="mt-1 col-12 col-md-6 col-lg-4">
                <div>
                  <input
                    type="text"
                    className={`form-control 
                    ${
                      nameValidator === false
                        ? "input-appito"
                        : "input-appito-error"
                    }
                    scale-in-center `}
                    placeholder="Nome"
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                  />
                </div>
              </div>

              <div className="mt-1 col-12 col-md-6 col-lg-4">
                <div className="">
                  <input
                    type="text"
                    className={`form-control 
                    ${
                      documentValidator === false
                        ? "input-appito"
                        : "input-appito-error"
                    }
                    scale-in-center `}
                    placeholder="CPF/CNPJ"
                    value={documentUser}
                    onChange={(event) =>
                      removeCharsDocument(event.target.value)
                    }
                  />
                </div>
              </div>

              <div className="mt-1 col-12 col-md-6 col-lg-4">
                <div className="col">
                  <input
                    type="text"
                    className={`form-control 
                    ${
                      emailValidator === false
                        ? "input-appito"
                        : "input-appito-error"
                    }
                    scale-in-center `}
                    placeholder="Email"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                  />
                </div>
              </div>

              <div className="mt-1 col-4 col-sm-2 col-md-2">
                <div className="col">
                  <select
                    type="text"
                    className={`form-control  form-select
                    ${
                      countryNumberValidator === false
                        ? "input-appito"
                        : "input-appito-error"
                    }
                    scale-in-center `}
                    placeholder="Country"
                    value={countryNumber}
                    onChange={(event) => setCountryNumber(event.target.value)}
                  >
                    {countries.map((country, index) => (
                      <option key={index} value={country.ddi}>
                        {country.ddi}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="mt-1 col-8 col-sm-10 col-md-4 col-lg-3">
                <div className="col">
                  <input
                    type="text"
                    className={`form-control 
                    ${
                      phoneValidator === false
                        ? "input-appito"
                        : "input-appito-error"
                    }
                    scale-in-center `}
                    placeholder="Telefone"
                    value={phone}
                    onChange={(event) => removeCharsPhone(event.target.value)}
                  />
                </div>
              </div>

              <div className="mt-1 col-12 col-md-4 col-lg-2">
                <div className="col">
                  <select
                    type="text"
                    className={`form-control form-select input-appito scale-in-center `}
                    placeholder="Source"
                    value={source}
                    onChange={(event) => setSource(event.target.value)}
                  >
                    {sources.map((bank, index) => (
                      <option key={index} value={bank.value}>
                        {bank.source}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="mt-1 col-12 col-md-4 col-lg-2">
                <div className="col">
                  <input
                    type="text"
                    className={`form-control 
                    ${
                      priceValidator === false
                        ? "input-appito"
                        : "input-appito-error"
                    }
                    scale-in-center `}
                    placeholder="Valor"
                    value={price}
                    onChange={(event) => removeCharsPrice(event.target.value)}
                  />
                </div>
              </div>

              <div className="mt-1  col-12 col-md-4 col-lg-3">
                <div className="col">
                  <input
                    type="date"
                    className={`form-control 
                    ${
                      dueDateValidator === false
                        ? "input-appito"
                        : "input-appito-error"
                    }
                    scale-in-center `}
                    placeholder="Vencimento"
                    value={dueDate}
                    onPaste={handlePaste}
                    onChange={(event) => setDueDate(event.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="row mt-1">
              <div
                className={`col-12 
                ${showInstallment === true ? "col-md-4" : "col-md-6"}
              `}
              >
                <div className="col ">
                  <select
                    type="text"
                    className={`form-control  form-select
                    ${
                      arenasValidator === false
                        ? "input-appito"
                        : "input-appito-error"
                    }
                    scale-in-center `}
                    placeholder="Arena"
                    value={arenaId}
                    onChange={(event) => setArenaId(event.target.value)}
                  >
                    {arenas.map((arena, index) => (
                      <option key={index} value={arena.arenaID}>
                        {arena.arenaName}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="col col-md-3">
                <div className="col">
                  <select
                    type="text"
                    className={`form-control  form-select
                    ${
                      bankIDValidator === false
                        ? "input-appito"
                        : "input-appito-error"
                    }
                    scale-in-center `}
                    placeholder="Banco"
                    value={bankID}
                    onChange={(event) => setBankID(event.target.value)}
                  >
                    {banks.map((bank, index) => (
                      <option key={index} value={bank.value}>
                        {bank.bankID}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="col col-md-3">
                <div className="col">
                  <select
                    type="text"
                    className={`form-control  form-select
                    ${
                      formOfPaymentValidator === false
                        ? "input-appito"
                        : "input-appito-error"
                    }
                    scale-in-center `}
                    placeholder="Forma de pagamento"
                    value={formOfPayment}
                    onChange={(event) =>
                      formOfPaymentCustom(event.target.value)
                    }
                  >
                    {formOfPayments.map((bank, index) => (
                      <option key={index} value={bank.value}>
                        {bank.formOfPayment}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div
                className={`col-3 col-md-2
                      ${showInstallment === false ? "d-none" : ""}
                  `}
              >
                <div>
                  <select
                    type="text"
                    className={`form-control form-select scale-in-center input-appito`}
                    placeholder="Parcelas"
                    value={installments}
                    onChange={(event) => setInstallments(event.target.value)}
                  >
                    <option value="1">1x</option>
                    <option value="2">2x</option>
                    <option value="3">3x</option>
                    <option value="4">4x</option>
                    <option value="5">5x</option>
                    <option value="6">6x</option>
                    <option value="7">7x</option>
                    <option value="8">8x</option>
                    <option value="9">9x</option>
                    <option value="10">10x</option>
                    <option value="11">11x</option>
                    <option value="12">12x</option>
                  </select>
                </div>
              </div>
            </div>

            {/* inicio endereco */}
            <div className=" mt-4 divisor" />
            <div className="row mt-4">
              <div className="d-flex align-items-center col-12 col-md-3 col-lg-3">
                <input
                  type="text"
                  className={`form-control 
                    ${
                      cepValidator === false
                        ? "input-appito"
                        : "input-appito-error"
                    }
                  scale-in-center `}
                  placeholder="CEP"
                  value={cep}
                  onChange={(event) => makeMaskCEP(event.target.value)}
                />
              </div>

              <div className="mt-1 col">
                <input
                  type="text"
                  className={`form-control 
                    ${
                      logradouroValidator === false
                        ? "input-appito"
                        : "input-appito-error"
                    }
                  scale-in-center `}
                  placeholder="Logradouro"
                  value={logradouro}
                  onChange={(event) => setLogradouro(event.target.value)}
                />
              </div>

              <div className="mt-1 col-4 col-md-2">
                <input
                  type="text"
                  className={`form-control 
                    ${
                      numeroValidator === false
                        ? "input-appito"
                        : "input-appito-error"
                    }
                  scale-in-center `}
                  placeholder="Número"
                  value={numero}
                  onChange={(event) => setNumero(event.target.value)}
                />
              </div>
            </div>

            <div className="mt-1 col">
              <input
                type="text"
                className={`form-control 
                  ${
                    addressComplementValidator === false
                      ? "input-appito"
                      : "input-appito-error"
                  }
                  scale-in-center `}
                placeholder="Complemento"
                value={addressComplement}
                onChange={(event) => setAddressComplement(event.target.value)}
              />
            </div>

            <div className="row mt-2">
              <div className="col">
                <input
                  type="text"
                  className={`form-control 
                      ${
                        bairroValidator === false
                          ? "input-appito"
                          : "input-appito-error"
                      }
                    scale-in-center `}
                  placeholder="Bairro"
                  value={bairro}
                  onChange={(event) => setBairro(event.target.value)}
                />
              </div>
              <div className="col ">
                <input
                  type="text"
                  className={`form-control mb-1
                      ${
                        localidadeValidator === false
                          ? "input-appito"
                          : "input-appito-error"
                      }
                    scale-in-center `}
                  placeholder="Cidade"
                  value={localidade}
                  onChange={(event) => setLocalidade(event.target.value)}
                />
              </div>

              <div className="col-3 col-md-2">
                <select
                  type="text"
                  className={`form-control  form-select
                      ${
                        ufValidator === false
                          ? "input-appito"
                          : "input-appito-error"
                      }
                    scale-in-center `}
                  placeholder="UF"
                  value={uf}
                  onChange={(event) => setUf(event.target.value)}
                >
                  <option value="RJ">RJ</option>
                  <option value="SP">SP</option>
                  <option value="RO">RO</option>
                  <option value="AC">AC</option>
                  <option value="RR">RR</option>
                  <option value="PA">PA</option>
                  <option value="AP">AP</option>
                  <option value="TO">TO</option>
                  <option value="MA">MA</option>
                  <option value="PI">PI</option>
                  <option value="CE">CE</option>
                  <option value="PB">PB</option>
                  <option value="PE">PE</option>
                  <option value="AL">AL</option>
                  <option value="SE">SE</option>
                  <option value="BA">BA</option>
                  <option value="MG">MG</option>
                  <option value="ES">ES</option>
                  <option value="PR">PR</option>
                  <option value="SC">SC</option>
                  <option value="RS">RS</option>
                  <option value="MS">MS</option>
                  <option value="MT">MT</option>
                  <option value="GO">GO</option>
                  <option value="DF">DF</option>
                </select>
              </div>
            </div>

            <div className=" mt-2 divisor" />

            {/* fim endereco */}

            <div>
              <Button
                text="Gerar Pagamento"
                typeButton="primary"
                onClick={() => handleClickGeneratePayment()}
              />
            </div>

            <div
              className={
                customLoader === true
                  ? "divisor-loader mt-4 mb-3"
                  : "divisor-green-1 mt-4 mb-3 "
              }
            />

            <div
              className={`row mt-4
                ${returnValidatorId === false ? "d-none" : ""}
                `}
            >
              <div className="d-flex align-items-center">
                <small id="returnid" className="form-text text-muted">
                  {returnId}
                </small>
              </div>
              <button
                className="btn button-secundary-appito col-3 col-sm-2 col-lg-1 scale-in-center "
                type="button"
                onClick={() => handleClickClipboardId()}
              >
                Copiar
              </button>
            </div>

            <div
              className={`row mt-4
                ${returnValidatorLink === false ? "d-none" : ""}
              `}
            >
              <div className="d-flex align-items-center">
                <small id="returnid" className="form-text text-muted">
                  {link}
                </small>
              </div>
              <button
                className="btn button-secundary-appito col-3 col-sm-2 col-lg-1 scale-in-center "
                type="button"
                onClick={() => handleClickClipboardLink()}
              >
                Copiar
              </button>
            </div>

            <div
              className={`row mt-4
                ${returnValidatorCodigo === false ? "d-none" : ""}
              `}
            >
              <div className="d-flex align-items-center">
                <small id="returnid" className="form-text text-muted">
                  {codigo}
                </small>
              </div>
              <button
                className="btn button-secundary-appito col-3 col-sm-2 col-lg-1 scale-in-center "
                type="button"
                onClick={() => handleClickClipboarCode()}
              >
                Copiar
              </button>
            </div>

            <div
              className={`row mt-4
                ${returnValidatorQrCode === false ? "d-none" : ""}
              `}
            >
              <div className="d-flex align-items-center">
                <small id="returnid" className="form-text text-muted">
                  {qrCode}
                </small>
              </div>
              <button
                className="btn button-secundary-appito col-3 col-sm-2 col-lg-1 scale-in-center "
                type="button"
                onClick={() => handleClickClipboarQRCode()}
              >
                Copiar
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Billing;
