import React from 'react';

import '../../style/global.css';

import Button from '../Button/index';
function Card({
  nome,
  title = 'titulo do card',
  msg = 'mensagem para o card',
  QtdProducts,
  onClick,
  TextButton,
}) {
  return (
    <div className='row justify-content-center  text-center scale-in-center  '>
      <div className='card  mb-2 list' style={{ maxWidth: '50rem' }}>
        <div className='card-body text-success '>
          <h5 className='text-subTitle-Appito card-title'>{title}</h5>
          <p className='card-text'>
            {msg}: {QtdProducts}
          </p>

          <div>
            <Button onClick={onClick} text={TextButton} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Card;
