/* eslint-disable import/no-anonymous-default-export */

const maskPhone = (v) => {
  var r = v.replace(/\D/g, "");
  r = r.replace(/^0/, "");
  if (r.length > 10) {
    r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1)$2-$3");
  } else if (r.length > 5) {
    r = r.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1)$2-$3");
  } else if (r.length > 2) {
    r = r.replace(/^(\d\d)(\d{0,5})/, "($1)$2");
  } else {
    r = r.replace(/^(\d*)/, "($1");
  }
  return r;
};

const maskInternacionalPhone = (phoneNumber) => {
  // Remove todos os caracteres não numéricos
  phoneNumber = phoneNumber.replace(/\D/g, "");

  // Verifica se o número é um telefone celular (11 dígitos) ou um telefone fixo (10 dígitos)
  const isCellPhone = phoneNumber.length === 11;

  // Aplica a máscara dependendo do tipo de telefone
  if (isCellPhone) {
    return `+${phoneNumber.slice(0, 2)} (${phoneNumber.slice(
      2,
      4
    )}) ${phoneNumber.slice(4, 9)}-${phoneNumber.slice(9)}`;
  } else {
    return `+${phoneNumber.slice(0, 2)} (${phoneNumber.slice(
      2,
      4
    )}) ${phoneNumber.slice(4, 8)}-${phoneNumber.slice(8)}`;
  }
};

const clearPhone = (v) => {
  v = v.replace("(", "").replace(")", "").replace("-", "").trim();
  return v;
};

const maskCPF = (v) => {
  v = v.replace(/\D/g, ""); //Remove tudo o que não é dígito
  v = v.replace(/(\d{3})(\d)/, "$1.$2"); //Coloca um ponto entre o terceiro e o quarto dígitos
  v = v.replace(/(\d{3})(\d)/, "$1.$2"); //Coloca um ponto entre o terceiro e o quarto dígitos
  //de novo (para o segundo bloco de números)
  v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2"); //Coloca um hífen entre o terceiro e o quarto dígitos
  return v;
};

const maskCEP = (v) => {
  v = v.replace(/\D/g, "");
  if (v.length > 5) {
    v = v.replace(/(\d{5})(\d{3})$/, "$1-$2");
  }
  return v;
};

const maskDot = (v) => {
  v = v.replace(",", ".");
  return v;
};

const maskComma = (v) => {
  v = v.replace(".", ",");
  return v;
};

function formatReal(int) {
  var tmp = int + "";
  tmp = tmp.replace(/([0-9]{2})$/g, ",$1");
  if (tmp.length < 6) tmp = tmp.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");

  return tmp;
}

const formatDateUTC = (dateWithOutFormat) => {
  let date = new Date(dateWithOutFormat);
  let dateFormated = date.toLocaleDateString("pt-BR", { timeZone: "UTC" });
  return dateFormated;
};

function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

function convertMoneyToBrazil(value) {
  var valor = value;

  valor = valor + "";
  valor = parseInt(valor.replace(/[\D]+/g, ""));
  valor = valor + "";
  valor = valor.replace(/([0-9]{2})$/g, ",$1");

  if (valor.length > 6) {
    valor = valor.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
  }

  if (valor.length > 9)
    valor = valor.replace(/([0-9]{3}).([0-9]{3}),([0-9]{2}$)/g, ".$1.$2,$3");

  if (valor.length > 12)
    valor = valor.replace(
      /([0-9]{3}).([0-9]{3}).([0-9]{3}),([0-9]{2}$)/g,
      ".$1.$2.$3,$4"
    );

  value = valor;
  if (valor === "NaN") value = "";
  return value;
}

function getRealConvert(money) {
  return money.toLocaleString("pt-br", { minimumFractionDigits: 2 });
}

const ConvertDot = (valueConvert) => {
  let newValueConvert = valueConvert.toString();
  let array = newValueConvert.split("");

  let teste = array.find((item) => {
    return item === ".";
  });

  if (teste) {
    let returnValue = "";
    let comma = false;

    array.forEach((value, index, totalValue) => {
      if (value === "." && !comma) {
        comma = true;

        if (index + 3 === totalValue.length) {
          return (returnValue = newValueConvert);
        } else {
          return (returnValue = newValueConvert + "0");
        }
      }
    });

    return returnValue.replace(".", ",");
  } else {
    return newValueConvert + ",00";
  }
};

export const cpfcnpj = (v) => {
  v = v.replace(/\D/g, "");

  if (v.length <= 11) {
    v = v.replace(/(\d{3})(\d)/, "$1.$2");
    v = v.replace(/(\d{3})(\d)/, "$1.$2");
    v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
  } else {
    v = v.substring(0, 14); // limita em 14 números
    v = v.replace(/^(\d{2})(\d)/, "$1.$2");
    v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
    v = v.replace(/\.(\d{3})(\d)/, ".$1/$2");
    v = v.replace(/(\d{4})(\d)/, "$1-$2");
  }

  return v;
};

export default {
  maskPhone,
  maskCPF,
  maskCEP,
  maskDot,
  maskComma,
  formatReal,
  formatDateUTC,
  convertMoneyToBrazil,
  getRealConvert,
  ConvertDot,
  cpfcnpj,
  formatDate,
  clearPhone,
  maskInternacionalPhone,
};
