import React, { useState, useEffect } from "react";

// mensagens de erro
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

import { useHistory } from "react-router-dom";

import "../../style/global.css";
import Button from "../../components/Button";

import api from "../../services/api";

import mask from "../../util/mask";

import { accessValidator } from "../../util/validationProfile";

import NavBar from "../../components/NavBar";

import BookingContractList from "../../components/BookingContractList";

import method from "../../services/apiLocal";
import CouponList from "../../components/CouponList";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import iconXGrey from "../../assets/icons/icon-x-grey.svg";

import { debounce } from "lodash";

import "./style.css";

// import iconLupaInfo from "../../assets/icons/booking-contract/whatsapp_red.svg";
import iconLupaInfo from "../../assets/icons/coupons/iconInfo.svg";

function Coupons() {
  let history = useHistory();

  const styleAddInfo = {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "0px",
    p: 4, // TODO: ESSE PANDING DA PROBLEMA NO CSS DO TITULO - DADOS COMPLEMENTARES

    position: "absolute",
    width: "768px",
    minHeight: "524px",

    background: "#FCFCFC",
    boxShadow:
      "0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.3);",
    borderRadius: "16px",
  };

  const styleCancel = {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "0px",
    p: 4, // TODO: ESSE PANDING DA PROBLEMA NO CSS DO TITULO - DADOS COMPLEMENTARES

    position: "absolute",
    width: "500px",
    minHeight: "160px",

    background: "#FCFCFC",
    boxShadow:
      "0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.3);",
    borderRadius: "16px",
  };

  //States
  const [customLoader, setCustomLoader] = useState(false);

  const [sendActive, setSendActive] = useState(true);

  const [categories, setCategories] = useState([]);
  const [categoryId, setCategoryId] = useState();
  let categoryIdSend = "";
  const [typeContracts, setTypeContracts] = useState([]);
  const [typeContractId, setTypeContract] = useState();
  let typeContractIdSend = "";
  const [spaces, setSpaces] = useState([]);
  const [spaceId, setSpaceId] = useState();
  let spaceIdSend = "";
  const [arenas, setArenas] = useState([]);
  const [arenaId, setArenaId] = useState();
  let arenaIdSend = "";
  const [status, setStatus] = useState([]);
  const [statusId, setStatusId] = useState();
  let statusIdSend = "";

  const [coupons, setCoupons] = useState([]);
  const [couponName, setCouponName] = useState("");

  let clearSearch = false;

  let couponNameSend = "";

  // PAGINAÇÃO
  const [pageNumber, setPageNumber] = useState(1);
  const [showList, setShowList] = useState(false);
  const [loadScreen, setLoadScreen] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  let currentPageSend = "";
  const [totalPages, setTotalPages] = useState(0);

  const [showPagination, setShowPagination] = useState(false);

  const [showPositionTwo, setShowPositionTwo] = useState(false);
  const [showPositionThree, setShowPositionThree] = useState(false);

  const [positionOne, setPositionOne] = useState(1);
  const [positionTwo, setPositionTwo] = useState(2);
  const [positionThree, setPositionThree] = useState(3);

  const [firstRowNum, setFirstRowNum] = useState();
  const [lastRowNum, setLastRowNum] = useState();
  const [totalRowNum, setTotalRowNum] = useState();

  // logica modal gabriel
  const [openDetails, setOpenDetails] = useState(false);
  const [couponDetails, setCouponDetails] = useState({});
  const [couponID, setCouponID] = useState();
  const [openCancel, setOpenCancel] = useState(false);

  const [isClickCancelCoupon, setIsClickCancelCoupon] = useState(true);

  // logica modal gabriel

  const getCategoriesFilter = async () => {
    try {
      const token = localStorage.getItem("token");
      const object = JSON.parse(token);

      let accessToken = "";
      if (object.accessToken) {
        accessToken = object.accessToken;
      }

      const response = await api.get(`api/suporte/GetFilterCouponCategorys`, {
        headers: { Authorization: "Bearer " + accessToken },
      });

      if (response.data.error === true) {
        toast.error(response.data.message);

        handleClickLoader();
        return;
      }

      setCategoryId(response.data.list[0].id);
      setCategories(response.data.list);
      categoryIdSend = response.data.list[0].id;
    } catch (error) {
      console.error(error);

      handleClickLoader();
      toast.error("Erro ao buscar categorias");
    }
  };

  const GetTypeFilter = async () => {
    try {
      const token = localStorage.getItem("token");
      const object = JSON.parse(token);

      let accessToken = "";
      if (object.accessToken) {
        accessToken = object.accessToken;
      }

      const response = await api.get(`api/suporte/GetFilterCouponTypes`, {
        headers: { Authorization: "Bearer " + accessToken },
      });

      if (response.data.error === true) {
        toast.error(response.data.message);

        handleClickLoader();
        return;
      }

      setTypeContracts(response.data.list);
      changeTypeContract(response.data.list[0].id);
      typeContractIdSend = response.data.list[0].id;

      validateSelects();
    } catch (error) {
      console.error(error);
      toast.error("Erro ao buscar tipos de contratos");

      handleClickLoader();
    }
  };

  const getArenaFilter = async () => {
    try {
      const token = localStorage.getItem("token");
      const object = JSON.parse(token);

      let accessToken = "";
      if (object.accessToken) {
        accessToken = object.accessToken;
      }

      const response = await api.get(`api/suporte/GetFilterCouponArenas`, {
        headers: { Authorization: "Bearer " + accessToken },
      });

      if (response.data.error === true) {
        toast.error(response.data.message);

        handleClickLoader();
        return;
      }

      setArenas(response.data.list);
      setArenaId(response.data.list[0].id);
      arenaIdSend = response.data.list[0].id;
      validateSelects();
    } catch (error) {
      console.error(error);

      handleClickLoader();
      toast.error("Erro ao buscar arenas");
    }
  };

  const getStatusFilter = async () => {
    try {
      const token = localStorage.getItem("token");
      const object = JSON.parse(token);

      let accessToken = "";
      if (object.accessToken) {
        accessToken = object.accessToken;
      }

      const response = await api.get(`api/suporte/GetFilterCouponStatus`, {
        headers: { Authorization: "Bearer " + accessToken },
      });

      if (response.data.error === true) {
        toast.error(response.data.message);

        handleClickLoader();
        return;
      }

      setStatus(response.data.list);
      changeStatus(response.data.list[0].id);
      statusIdSend = response.data.list[0].id;

      validateSelects();
    } catch (error) {
      console.error(error);

      handleClickLoader();
      toast.error("Erro ao buscar status");
    }
  };

  const getSpacesFilter = async () => {
    try {
      const token = localStorage.getItem("token");
      const object = JSON.parse(token);

      let accessToken = "";
      if (object.accessToken) {
        accessToken = object.accessToken;
      }

      const response = await api.get(`api/suporte/GetFilterCouponSpaces`, {
        headers: { Authorization: "Bearer " + accessToken },
      });

      if (response.data.error === true) {
        toast.error(response.data.message);

        handleClickLoader();
        return;
      }

      setSpaces(response.data.list);
      changeSpace(response.data.list[0].id);
      spaceIdSend = response.data.list[0].id;
      validateSelects();
    } catch (error) {
      console.error(error);

      handleClickLoader();
      toast.error("Erro ao buscar espaços");
    }
  };

  const getCoupons = async () => {
    try {
      setCustomLoader(true);

      const token = localStorage.getItem("token");
      const object = JSON.parse(token);
      let accessToken = "";
      if (object.accessToken) {
        accessToken = object.accessToken;
      }

      const url = createUrl();

      const response = await api.get(`api/suporte/GetCoupons${url}`, {
        headers: { Authorization: "Bearer " + accessToken },
      });

      if (response.data.list.length === 0) {
        setCoupons([]);
        setShowList(false);
      } else {
        setCoupons([]); // limpando array

        setFirstRowNum(response.data?.firstRowNum);
        setLastRowNum(response.data?.lastRowNum);
        setTotalRowNum(response.data?.totalRowNum);

        setCoupons(response.data?.list);
        setCurrentPage(response.data?.currentPage);
        setTotalPages(response.data?.totalPages);
        setShowList(true);

        const totalPages = response.data?.totalPages;
        if (totalPages == 2) {
          setShowPositionTwo(true);
        } else if (totalPages == 3) {
          setShowPositionThree(true);
        } else if (totalPages > 1) {
          // menos que 10 registros nao mostra paginação
          setShowPagination(true);
        }
      }

      if (response.data.error === true) {
        toast.error(response.data.message);
        setShowList(false);

        setCustomLoader();

        return;
      }

      setLoadScreen(false);
      setCustomLoader();
    } catch (error) {
      console.error(error);
      setShowList(false);

      toast.error("Erro ao buscar cupons");
    }
  };

  const createUrl = () => {
    let url = "";

    let getCoupon = couponNameSend || couponName;
    if (clearSearch) {
      getCoupon = "";
    }

    if (getCoupon !== "" && getCoupon !== undefined) {
      if (url.length > 0) {
        url += "&name=" + getCoupon;
      } else {
        url += "?name=" + getCoupon;
      }
    }

    let getArenaId = arenaIdSend || arenaId;
    if (
      getArenaId !== "" &&
      getArenaId !== undefined &&
      getArenaId !== "00000000-0000-0000-0000-000000000000"
    ) {
      if (url.length > 0) {
        url += "&arenaID=" + getArenaId;
      } else {
        url += "?arenaID=" + getArenaId;
      }
    }

    let getCategorys = categoryIdSend || categoryId;
    if (
      getCategorys !== "" &&
      getCategorys !== undefined &&
      getCategorys != 0
    ) {
      if (url.length > 0) {
        url += "&categoryID=" + getCategorys;
      } else {
        url += "?categoryID=" + getCategorys;
      }
    }

    let getspaceId = spaceIdSend || spaceId;
    if (getspaceId !== "" && getspaceId !== undefined && getspaceId != 0) {
      if (url.length > 0) {
        url += "&spaceType=" + getspaceId;
      } else {
        url += "?spaceType=" + getspaceId;
      }
    }

    let getStatus = statusIdSend || statusId;
    if (getStatus !== "" && getStatus !== undefined && getStatus != 0) {
      if (url.length > 0) {
        url += "&status=" + getStatus;
      } else {
        url += "?status=" + getStatus;
      }
    }

    let getTypeContractId = typeContractIdSend || typeContractId;
    if (
      getTypeContractId !== "" &&
      getTypeContractId !== undefined &&
      getTypeContractId != 0
    ) {
      if (url.length > 0) {
        url += "&couponType=" + getTypeContractId;
      } else {
        url += "?couponType=" + getTypeContractId;
      }
    }

    let getCurrentPage = currentPageSend || currentPage;
    if (
      getCurrentPage !== "" &&
      getCurrentPage !== undefined &&
      getCurrentPage != 0
    ) {
      if (url.length > 0) {
        url += "&pageNumber=" + getCurrentPage;
      } else {
        url += "?pageNumber=" + getCurrentPage;
      }
    }

    return url;
  };

  const onClickButtonFooter = async () => {
    try {
      history.push("/newCoupon");
    } catch (error) {
      console.error(error);
      toast.error("Erro ao criar cupom");
    }
  };

  const onClickSearchText = () => {
    if (couponName !== "") {
      resetPagination();
      validateSelects();
      clearSearch = false;
    } else {
      clearSearch = true;

      resetPagination();
      validateSelects();
    }
  };

  const changeCoupon = (newCoupon) => {
    if (!/\s/.test(newCoupon)) {
      // Converter para maiúsculas
      newCoupon = newCoupon.toUpperCase();
      // Atualizar o estado
      setCouponName(newCoupon);
      couponNameSend = newCoupon;
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      // Chame a função de ação aqui
      onClickSearchText();
    }
  };

  const changeArena = (arenaId) => {
    resetPagination();
    setArenaId(arenaId);
    arenaIdSend = arenaId;
    validateSelects();
  };

  const changeSpace = (id) => {
    resetPagination();
    setSpaceId(id);
    spaceIdSend = id;
    validateSelects();
  };

  const changeTypeContract = (type) => {
    resetPagination();
    setTypeContract(type);
    typeContractIdSend = type;
    validateSelects();
  };

  const changeCategory = (categoryId) => {
    resetPagination();
    setCategoryId(categoryId);
    categoryIdSend = categoryId;
    validateSelects();
  };

  const changeStatus = (statusId) => {
    resetPagination();
    setStatusId(statusId);
    statusIdSend = statusId;
    validateSelects();
  };

  const resetPagination = () => {
    setCurrentPage(1);
    currentPageSend = 1;
    setShowPagination(false);
    setShowPositionTwo(false);
    setShowPositionThree(false);
    setPositionOne(1);
    setPositionTwo(2);
    setPositionThree(3);
  };
  const validateSelects = async () => {
    if (loadScreen === false) {
      // condição não se aplica quando a tela é carregada a primeira vez
      if (
        categoryIdSend ||
        typeContractIdSend ||
        spaceIdSend ||
        arenaIdSend ||
        statusIdSend ||
        couponNameSend ||
        couponNameSend === ""
      ) {
        getCoupons();
        setShowList(true);
      }
    }
  };

  const headerRender = () => {
    return (
      <>
        <div className="show-header">
          <div className="row mb-3">
            <div className="search-coupon">
              <div>
                <input
                  type="text"
                  className={`input-coupon form-control scale-in-center `}
                  placeholder="Nome do cupom"
                  value={couponName}
                  onChange={(event) => changeCoupon(event.target.value)}
                  onKeyPress={handleKeyPress}
                />
              </div>

              <div
                className="icon-coupon"
                onClick={(event) => onClickSearchText()}
              >
                <i className="bi bi-search"></i>
              </div>
            </div>

            <div className="category-list">
              <div className="col">
                <select
                  type="text"
                  className={`form-control  form-select scale-in-center BCS-select`}
                  placeholder="Categorias"
                  value={categoryId}
                  onChange={(event) => changeCategory(event.target.value)}
                >
                  {categories.map((category, index) => (
                    <option key={index} value={category.id}>
                      {category.description}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="type-list">
              <div className="col">
                <select
                  type="text"
                  className={`form-control  form-select scale-in-center BCS-select`}
                  placeholder="Tipos"
                  value={typeContractId}
                  onChange={(event) => changeTypeContract(event.target.value)}
                >
                  {typeContracts.map((type, index) => (
                    <option key={index} value={type.id}>
                      {type.description}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="filter-coupon-arena-list">
              <div className="col">
                <select
                  type="text"
                  className={`form-control form-select scale-in-center BCS-select`}
                  placeholder="Arenas"
                  value={arenaId}
                  onChange={(event) => changeArena(event.target.value)}
                >
                  {arenas.map((arena, index) => (
                    <option key={index} value={arena.id}>
                      {arena.description}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="spaces-list">
              <div className="col">
                <select
                  type="text"
                  className={`form-control form-select scale-in-center BCS-select`}
                  placeholder=""
                  value={spaceId}
                  onChange={(event) => changeSpace(event.target.value)}
                >
                  {spaces.map((arena, index) => (
                    <option key={index} value={arena.id}>
                      {arena.description}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="status-list">
              <div className="col">
                <select
                  type="text"
                  className={`form-control  form-select scale-in-center BCS-select`}
                  placeholder=""
                  value={statusId}
                  onChange={(event) => changeStatus(event.target.value)}
                >
                  {status.map((space, index) => (
                    <option key={index} value={space.id}>
                      {space.description}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          {/* TITULO da listagem */}
          <div className="header-list">
            <div className="coupon-code">Nome</div>
            <div className="coupon-value">Valor</div>
            <div className="coupon-userType">Usuário</div>
            <div className="coupon-bookingType">Tipo</div>
            <div className="coupon-spaceType">Espaço</div>
            <div className="coupon-status">Status</div>

            {/* <div className="default-column-14">
                <div className="text-truncate">Dia/horário</div>
              </div> */}
          </div>

          <div
            className={customLoader === true ? "coupon-divisor-loader " : ""}
          />
        </div>
      </>
    );
  };

  const handleClickLoader = (loader = false) => {
    setCustomLoader(loader);
  };

  // logica modal gabriel

  const handleCloseDetails = () => setOpenDetails(false);

  const handleCloseCancel = () => setOpenCancel(false);

  const handleOpenDetails = async (id) => {
    changeLoader(true);

    await getDetails(id);

    setOpenDetails(true);

    changeLoader(false);
  };

  const changeLoader = (Change) => {
    setCustomLoader(Change);
  };

  // logica modal gabriel

  const getDetails = async (id) => {
    const token = localStorage.getItem("token");
    const object = JSON.parse(token);
    let accessToken = "";
    if (object.accessToken) {
      accessToken = object.accessToken;
    }

    const response = await api.get(
      `api/suporte/GetCouponDetails?couponID=${id}`,
      {
        headers: { Authorization: "Bearer " + accessToken },
      }
    );
    setCouponID(id);
    setCouponDetails(response.data);
  };

  const cancelCoupon = async (id) => {
    try {
      const token = localStorage.getItem("token");
      const object = JSON.parse(token);

      let accessToken = "";
      if (object.accessToken) {
        accessToken = object.accessToken;
      }

      const data = {
        couponID: couponID,
        couponIDInt: 0,
      };

      const response = await api.put(`api/suporte/CancelCoupon`, data, {
        headers: { Authorization: "Bearer " + accessToken },
      });

      if (response.data?.error === true) {
        toast.error(response.data?.message);
      } else {
        if (response.data?.message) {
          toast.success(response.data?.message);
        }
      }

      await getCoupons();
    } catch (error) {
      console.error(error);

      toast.error("Erro ao cancelar cupom");
    }
  };

  const handleClickCancelCupom = async () => {
    setOpenCancel(true);
  };

  const handleClickUpdateCancelCupom = async () => {
    setIsClickCancelCoupon(false); // desabilita o botão de cancelamento
    await cancelCoupon();
    setOpenCancel(false);
    setOpenDetails(false);
    setIsClickCancelCoupon(true); // libera o botão de cancelamento
  };

  const handleClickCloseCancelCupom = () => {
    setOpenCancel(false);
  };

  const changePosition = (position) => {
    setCurrentPage(position);
    currentPageSend = position;
    getCoupons();
  };

  const onClickPositionThree = (position) => {
    if (totalPages > position) {
      const newPosition = positionThree + 1;
      setPositionOne(positionTwo);
      setPositionTwo(positionThree);
      setPositionThree(newPosition);
      changePosition(position);
    }

    if (totalPages === position) {
      changePosition(position);
    }
  };

  const onClickPositionOne = (position) => {
    if (position > 1) {
      const newPosition = positionOne - 1;
      setPositionOne(newPosition);
      setPositionTwo(positionOne);
      setPositionThree(positionTwo);
      changePosition(position);
    }
    if (position === 1) {
      setPositionOne(1);
      setPositionTwo(2);
      setPositionThree(3);
      changePosition(position);
    }
  };

  const onClickPrevious = () => {
    if (currentPage === 1) {
      return;
    }

    if (currentPage === totalPages) {
      changePosition(currentPage - 1);
      return;
    }

    if (currentPage > 2) {
      changePosition(currentPage - 1);
      setPositionOne(positionOne - 1);
      setPositionTwo(positionTwo - 1);
      setPositionThree(positionThree - 1);
      return;
    }

    if (currentPage === 2) {
      changePosition(currentPage - 1);
      return;
    }
  };

  const onClickNext = () => {
    if (currentPage === totalPages) {
      return;
    }

    if (currentPage === 1) {
      changePosition(currentPage + 1); // primeira posição não reposiciona
      return;
    }

    if (currentPage === totalPages - 1) {
      changePosition(currentPage + 1);
      return;
    }

    if (currentPage < totalPages) {
      changePosition(currentPage + 1);
      setPositionOne(positionOne + 1);
      setPositionTwo(positionTwo + 1);
      setPositionThree(positionThree + 1);
      return;
    }
  };

  const valideModalStatus = (status) => {
    let validade = false;

    switch (status) {
      case "A":
        validade = true;
        break;
      case "D":
        validade = true;
        break;
      default:
        validade = false;
        break;
    }

    return validade;
  };

  useEffect(() => {
    getCategoriesFilter();
    getArenaFilter();
    getSpacesFilter();
    GetTypeFilter();
    getStatusFilter();
    getCoupons();
  }, []);

  return (
    <>
      <NavBar
        headerRender={headerRender}
        activeButton={sendActive}
        hiddenFooter={true}
        textButtonFooter={"Criar cupom"}
        onClickButtonFooter={onClickButtonFooter}
      >
        <section>
          {/* MODAL CANCEL */}
          <div>
            <Modal
              open={openCancel}
              onClose={handleCloseCancel}
              aria-labelledby="keep-mounted-modal-title"
              aria-describedby="keep-mounted-modal-description"
            >
              <div className="modal-container">
                <Box sx={styleCancel} key={couponID}>
                  <div className="d-flex justify-content-between">
                    <div>
                      <Typography
                        id="keep-mounted-modal-title"
                        variant="h6"
                        component="h2"
                        className="modal-header-text"
                      >
                        Cancelar cupom?
                      </Typography>
                    </div>

                    <div>
                      <div className="SCH-pointer-badge">
                        <img
                          alt="logo"
                          src={iconXGrey}
                          onClick={handleClickCloseCancelCupom}
                        />
                      </div>
                    </div>
                  </div>
                  {/* fim do header */}
                  <div className="modal-cancel-desc">
                    Este cupom será cancelado e não poderá mais ser utilizado.
                    Essa ação não poderá ser desfeita.
                  </div>
                  <div className={"coupon-divisor-green-cancel-modal"} />

                  <div className="coupon-modal-button mt-2">
                    <div>
                      <Button
                        text="Fechar"
                        typeButton="default"
                        onClick={() => handleClickCloseCancelCupom()}
                        style="col-12 text-center scale-in-center"
                      />
                    </div>
                    <div style={{ marginLeft: "10px" }}>
                      {isClickCancelCoupon === true ? (
                        <Button
                          text="Cancelar cupom"
                          typeButton="error-secundary"
                          onClick={() => handleClickUpdateCancelCupom()}
                          style="col-12 text-center scale-in-center"
                        />
                      ) : (
                        <Button
                          text="Cancelar cupom"
                          typeButton="error-secundary"
                          onClick={() => handleClickUpdateCancelCupom()}
                          style="col-12 text-center scale-in-center"
                          isDisabled={!isClickCancelCoupon}
                        />
                      )}
                    </div>
                  </div>
                </Box>
              </div>
            </Modal>
          </div>
          {/* MODAL DETAILS */}
          <div>
            <Modal
              open={openDetails}
              onClose={handleCloseDetails}
              aria-labelledby="keep-mounted-modal-title"
              aria-describedby="keep-mounted-modal-description"
            >
              <div className="modal-container">
                <Box sx={styleAddInfo} key={couponID}>
                  <div className="d-flex justify-content-between">
                    <div>
                      <Typography
                        id="keep-mounted-modal-title"
                        variant="h6"
                        component="h2"
                        className="modal-header-text"
                      >
                        Detalhes do cupom
                      </Typography>
                    </div>

                    <div>
                      <div className="SCH-pointer-badge">
                        <img
                          alt="logo"
                          src={iconXGrey}
                          onClick={handleCloseDetails}
                        />
                      </div>
                    </div>
                  </div>
                  {/* fim do header */}
                  <div>
                    <div className="row">
                      <div className="coupon-modal-line-2">
                        <div>Nome:</div>
                        <div>{couponDetails?.name}</div>
                      </div>
                      <div className="coupon-modal-line-2">
                        <div>Categoria:</div>
                        <div>{couponDetails?.category}</div>
                      </div>
                    </div>
                    {/* LINHA1 */}
                    <div className="row">
                      <div className="coupon-modal-line-2">
                        <div>Status:</div>
                        <div className="coupon-status">
                          <div className="col text-center d-flex justify-content-start">
                            <h5
                              className={`text-center ${
                                couponDetails?.status === "E"
                                  ? "coupon-expirado componentBadge "
                                  : "d-none"
                              } `}
                            >
                              Expirado
                            </h5>

                            <h5
                              className={`text-center ${
                                couponDetails?.status === "C"
                                  ? "coupon-cancelado componentBadge "
                                  : "d-none"
                              } `}
                            >
                              Cancelado
                            </h5>

                            <h5
                              className={`text-center ${
                                couponDetails?.status === "D"
                                  ? "coupon-disponivel componentBadge "
                                  : "d-none"
                              } `}
                            >
                              Disponível
                            </h5>

                            <h5
                              className={`text-center ${
                                couponDetails?.status === "A"
                                  ? "coupon-agendado componentBadge "
                                  : "d-none"
                              } `}
                            >
                              Agendado
                            </h5>
                          </div>
                        </div>
                      </div>
                      <div className="coupon-modal-line-2">
                        <div>Qtd. já usada:</div>
                        <div>{couponDetails?.quantityUse}</div>
                      </div>
                    </div>
                    {/* LINHA2 */}
                    <div className={"coupon-divisor-green-modal"} />

                    <div className="row">
                      <div className="coupon-modal-line-2">
                        <div>Quando o cupom pode ser utilizado:</div>
                        <div>{couponDetails?.couponUseDate}</div>
                      </div>
                      <div className="coupon-modal-line-2">
                        <div>Quando o desconto pode ser aplicado:</div>
                        <div>{couponDetails?.discountUseDate}</div>
                      </div>
                    </div>
                    {/* LINHA3 */}
                    <div className="row">
                      <div className="coupon-modal-line-2">
                        <div>Dias da semana:</div>
                        <div>{couponDetails?.weekdays}</div>
                      </div>
                    </div>
                    {/* LINHA4 */}

                    <div className={"coupon-divisor-green-modal"} />
                    <div className="row">
                      <div className="coupon-modal-line-2">
                        <div>Arena/Unidade:</div>
                        <div>{couponDetails?.arena}</div>
                      </div>
                      <div className="coupon-modal-line-2">
                        <div>Tipo de usuário:</div>
                        <div>{couponDetails?.userType}</div>
                      </div>
                    </div>
                    {/* LINHA5 */}

                    <div className="row">
                      <div className="coupon-modal-line-2">
                        <div>Tipo da reserva:</div>
                        <div>{couponDetails?.bookingType}</div>
                      </div>
                      <div className="coupon-modal-line-2">
                        <div>Qtd. máxima de uso:</div>
                        <div>{couponDetails?.limitUse}</div>
                      </div>
                    </div>
                    {/* LINHA6 */}

                    <div className="row">
                      <div className="coupon-modal-line-2">
                        <div>Tipo de desconto:</div>
                        <div>{couponDetails?.discountType}</div>
                      </div>
                      <div className="coupon-modal-line-2">
                        <div>Tipo de espaço:</div>
                        <div>{couponDetails?.spaceType}</div>
                      </div>
                    </div>
                    {/* LINHA7 */}
                  </div>

                  {valideModalStatus(couponDetails?.status) === true ? (
                    <div>
                      <div className={"coupon-divisor-green-modal"} />

                      <div className="coupon-modal-button">
                        <div>
                          <Button
                            text="Cancelar cupom"
                            typeButton="error-primary"
                            onClick={() => handleClickCancelCupom()}
                            style="col-12 text-center scale-in-center"
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </Box>
              </div>
            </Modal>
          </div>
          <div className="container ">
            <div className="row justify-content-center ">
              <div className="col-12 col-md-12 col-lg-10 col-xl-10 text-center ">
                {showList === true ? (
                  <div className="mt-2">
                    {coupons.map((coupon) => (
                      <CouponList
                        key={coupon.id}
                        coupon={coupon}
                        onClickDetails={() => handleOpenDetails(coupon.id)}
                      />
                    ))}

                    {showPositionTwo === true ? (
                      <div className="pagination-organize">
                        <div className="pagination-info">
                          <div>
                            Exibindo {firstRowNum}-{lastRowNum} de {totalRowNum}
                          </div>
                        </div>
                        <div className="pagination">
                          <nav aria-label="Page navigation example">
                            <ul className="pagination">
                              <li className="page-item">
                                <p
                                  className={`page-link ${
                                    currentPage === positionOne
                                      ? "pagination-number-choice"
                                      : "pagination-number"
                                  } `}
                                  onClick={() =>
                                    onClickPositionOne(positionOne)
                                  }
                                >
                                  {positionOne}
                                </p>
                              </li>

                              <li>
                                <p
                                  className={`page-link ${
                                    currentPage === positionTwo
                                      ? "pagination-number-choice"
                                      : "pagination-number"
                                  } `}
                                  onClick={() => changePosition(positionTwo)}
                                >
                                  {positionTwo}
                                </p>
                              </li>
                            </ul>
                          </nav>
                        </div>
                      </div>
                    ) : (
                      <div></div>
                    )}

                    {showPositionThree === true ? (
                      <div className="pagination-organize">
                        <div className="pagination-info">
                          <div>
                            Exibindo {firstRowNum}-{lastRowNum} de {totalRowNum}
                          </div>
                        </div>
                        <div className="pagination">
                          <nav aria-label="Page navigation example">
                            <ul className="pagination">
                              <li className="page-item">
                                <p
                                  className={`page-link ${
                                    currentPage === positionOne
                                      ? "pagination-number-choice"
                                      : "pagination-number"
                                  } `}
                                  onClick={() =>
                                    onClickPositionOne(positionOne)
                                  }
                                >
                                  {positionOne}
                                </p>
                              </li>

                              <li>
                                <p
                                  className={`page-link ${
                                    currentPage === positionTwo
                                      ? "pagination-number-choice"
                                      : "pagination-number"
                                  } `}
                                  onClick={() => changePosition(positionTwo)}
                                >
                                  {positionTwo}
                                </p>
                              </li>
                              <li className="page-item">
                                <p
                                  className={`page-link ${
                                    currentPage === positionThree
                                      ? "pagination-number-choice"
                                      : "pagination-number"
                                  } `}
                                  onClick={() =>
                                    onClickPositionThree(positionThree)
                                  }
                                >
                                  {positionThree}
                                </p>
                              </li>
                            </ul>
                          </nav>
                        </div>
                      </div>
                    ) : (
                      <div></div>
                    )}

                    {showPagination === true ? (
                      <div className="pagination-organize">
                        <div className="pagination-info">
                          <div>
                            Exibindo {firstRowNum}-{lastRowNum} de {totalRowNum}
                          </div>
                        </div>
                        <div className="pagination">
                          <nav aria-label="Page navigation example">
                            <ul className="pagination">
                              <li className="page-item">
                                <p
                                  className="page-link pagination-number "
                                  href="#"
                                  aria-label="Previous"
                                  onClick={() => onClickPrevious()}
                                  style={{ borderRadius: "20px 0 0 20px " }}
                                >
                                  <span aria-hidden="true">
                                    <i className="bi bi-chevron-compact-left"></i>
                                  </span>
                                </p>
                              </li>
                              <li className="page-item">
                                <p
                                  className={`page-link ${
                                    currentPage === positionOne
                                      ? "pagination-number-choice"
                                      : "pagination-number"
                                  } `}
                                  onClick={() =>
                                    onClickPositionOne(positionOne)
                                  }
                                >
                                  {positionOne}
                                </p>
                              </li>

                              <li>
                                <p
                                  className={`page-link ${
                                    currentPage === positionTwo
                                      ? "pagination-number-choice"
                                      : "pagination-number"
                                  } `}
                                  onClick={() => changePosition(positionTwo)}
                                >
                                  {positionTwo}
                                </p>
                              </li>
                              <li className="page-item">
                                <p
                                  className={`page-link ${
                                    currentPage === positionThree
                                      ? "pagination-number-choice"
                                      : "pagination-number"
                                  } `}
                                  onClick={() =>
                                    onClickPositionThree(positionThree)
                                  }
                                >
                                  {positionThree}
                                </p>
                              </li>

                              <li className="page-item">
                                <p
                                  className="page-link pagination-number"
                                  href="#"
                                  aria-label="Next"
                                  onClick={() => onClickNext()}
                                  style={{ borderRadius: "0 20px 20px 0" }}
                                >
                                  <span aria-hidden="true">
                                    <i className="bi bi-chevron-compact-right"></i>
                                  </span>
                                </p>
                              </li>
                            </ul>
                          </nav>
                        </div>
                      </div>
                    ) : (
                      // paginação menos que 10 registros
                      <div></div>
                    )}
                  </div>
                ) : (
                  <div className="mt-4">
                    <div>
                      <img src={iconLupaInfo} alt="logo" />
                    </div>
                    <div className="coupon-info-title mt-2">
                      Nenhum registro encontrado
                    </div>
                    <div className="coupon-info-subtitle">
                      Tente novamente com outros filtros.
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </NavBar>
    </>
  );
}

export default Coupons;
