import React, { useState, useEffect } from "react";

// mensagens de erro
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

import { useHistory } from "react-router-dom";

import "../../style/global.css";
import Button from "../../components/Button";

import api from "../../services/api";

import mask from "../../util/mask";

import { accessValidator } from "../../util/validationProfile";

import method from "../../services/apiLocal";

import "./style.css";

function NewCoupon() {
  let history = useHistory();

  //States
  const [customLoader, setCustomLoader] = useState(false);

  const [dueDate, setDueDate] = useState("");
  const [dueDateValidator, setDueDateValidator] = useState(false);

  const [isClickSaveCoupon, setIsClickSaveCoupon] = useState(true);

  // nome do cupom
  const [couponName, setCouponName] = useState("");
  const [couponNameValidator, setCouponNameValidator] = useState(false);
  const [couponNameReturn, setCouponNameReturn] = useState("");
  const [couponNameValidateReturn, setCouponNameValidateReturn] =
    useState(false);

  const [categorys, setCategorys] = useState([]);
  const [categoryID, setCategoryID] = useState();
  const [categoryIDValidator, setCategoryIDValidator] = useState(false);

  // data de inicio e fim
  const [beginDate, setBeginDate] = useState();
  const [minBeginDate, setMinBeginDate] = useState();
  const [beginDateValidator, setBeginDateValidator] = useState(false);

  const [expirationDate, setExpirationDate] = useState();
  const [minExpirationDate, setMinExpirationDate] = useState();
  const [expirationDateValidator, setExpirationDateValidator] = useState(false);

  const [initUseDate, setInitUseDate] = useState();
  const [minInitUseDate, setMinInitUseDate] = useState();
  const [initUseDateValidator, setInitUseDateValidator] = useState(false);

  const [finishUseDate, setFinishUseDate] = useState();
  const [minFinishUseDate, setMinFinishUseDate] = useState();
  const [finishUseDateValidator, setFinishUseDateValidator] = useState(false);

  const [today, setToday] = useState(null);

  // dias da semana
  const [mondayCheck, setMondayCheck] = useState(false);
  const [mondayID, setMondayID] = useState(2);

  const [tuesdayCheck, setTuesdayCheck] = useState(false);
  const [tuesdayID, setTuesdayID] = useState(3);

  const [wednesdayCheck, setWednesdayCheck] = useState(false);
  const [wednesdayID, setWednesdayID] = useState(4);

  const [thursdayCheck, setThursdayCheck] = useState(false);
  const [thursdayID, setThursdayID] = useState(5);

  const [fridayCheck, setFridayCheck] = useState(false);
  const [fridayID, setFridayID] = useState(6);

  const [saturdayCheck, setSaturdayCheck] = useState(false);
  const [saturdayID, setSaturdayID] = useState(7);

  const [sundayCheck, setSundayCheck] = useState(false);
  const [sundayID, setSundayID] = useState(1);

  const [weekDaysValidator, setWeekDaysValidator] = useState(false);

  // Arena/Unidade
  const [arenas, setArenas] = useState([]);
  const [arenasValidator, setArenasValidator] = useState(false);

  // Usuário
  const [userForms, setUserForms] = useState([]);
  const [userFormId, setUserFormId] = useState();

  const [phone, setPhone] = useState("");
  const [phoneValidator, setPhoneValidator] = useState(false);
  const [phoneReturn, setPhoneReturn] = useState(false);

  const [nickname, setNickname] = useState("");
  const [nicknameValidator, setNicknameValidator] = useState(false);
  const [nicknameReturn, setNicknameReturn] = useState(false);

  const [userValidateMessage, setUserValidateMessage] = useState("");

  const [oneUserCheck, setOneUserCheck] = useState(false);
  const [oneUserId, setOneUserId] = useState(1);

  const [allUserCheck, setAllUserCheck] = useState(true);
  const [allUserId, setAllUserId] = useState(2);

  const [isTelefone, setIsTelefone] = useState();

  const [countryNumber, setCountryNumber] = useState("+55");
  const [countryNumberValidator, setCountryNumberValidator] = useState(false);
  const [countries, setCountries] = useState([]);

  // Tipo de reserva
  const [bookingTypeO, setBookingTypeO] = useState(true);

  const [bookingTypeR, setBookingTypeR] = useState(false);

  const [firstBooking, setFirstBooking] = useState(true);

  const [allBookings, setAllBookings] = useState(false);

  // Qtd. Máxima de uso
  const [limitedCheck, setLimitedCheck] = useState(false);
  const [limitedID, setLimitedID] = useState(1);

  const [ilimitedCheck, setIlimitedCheck] = useState(true);
  const [ilimitedID, setIlimitedID] = useState(2);

  const [limit, setLimit] = useState(1);
  const [limitValidator, setLimitValidator] = useState(false);

  // tipo de desconto
  const [priceDiscountCheck, setPriceDiscountCheck] = useState(true);
  const [priceDiscountID, setPriceDiscountID] = useState("V");

  const [percentageDiscountCheck, setPercentageDiscountCheck] = useState(false);
  const [percentageDiscountID, setPercentageDiscountID] = useState("P");

  const [price, setPrice] = useState();
  const [priceValidator, setPriceValidator] = useState(false);

  // tipo de espaço
  const [societyCheck, setSocietyCheck] = useState(false);
  const [societySpaceID, setSocietySpaceID] = useState("Q");

  const [areiaCheck, setAreiaCheck] = useState(false);
  const [areiaSpaceID, setAreiaSpaceID] = useState("A");

  const [churrasqueiraCheck, setChurrasqueiraCheck] = useState(false);
  const [churrasqueiraSpaceID, setChurrasqueiraSpaceID] = useState("C");

  const [spaceTypeValidator, setSpaceTypeValidator] = useState(false);

  const handleClickLoader = (loader = false) => {
    setCustomLoader(loader);
  };

  const removeCharsPhone = (characteres) => {
    let newPhone = mask.maskPhone(characteres);
    // let newPhone = mask.maskInternacionalPhone(characteres);
    setPhone(newPhone);
  };

  const removeCharsPrice = (characteres) => {
    let newValue = mask.convertMoneyToBrazil(characteres);

    let validateValue = 0;
    if (newValue === "" || newValue === undefined) {
      validateValue = 0;
    } else {
      validateValue = parseFloat(
        newValue.replace(/[.]/g, "").replace(",", ".")
      );
    }

    if (validateValue >= 0 && validateValue <= 9999.99) {
      // limite para o valor de um cupom é de  9999,99
      setPrice(newValue);
    }
  };

  async function dataValidator() {
    let validatorImpedimento = true;

    if (couponName === "") {
      setCouponNameValidator(true);
      validatorImpedimento = false;
    } else {
      if (couponName.length < 4 || couponName.length > 25) {
        setCouponNameValidator(true);
        validatorImpedimento = false;
      } else {
        setCouponNameValidator(false);
      }
    }

    if (categoryID === "0" || categoryID === undefined) {
      setCategoryIDValidator(true);
      validatorImpedimento = false;
    } else {
      setCategoryIDValidator(false);
    }

    if (beginDate === undefined) {
      setBeginDateValidator(true);
      validatorImpedimento = false;
    } else {
      if (today > beginDate) {
        setBeginDateValidator(true);
        validatorImpedimento = false;
      } else {
        setBeginDateValidator(false);
      }
    }

    if (expirationDate === undefined) {
      setExpirationDateValidator(true);
      validatorImpedimento = false;
    } else {
      if (beginDate > expirationDate) {
        setExpirationDateValidator(true);
        validatorImpedimento = false;
      } else {
        setExpirationDateValidator(false);
      }
    }

    if (initUseDate === undefined) {
      setInitUseDateValidator(true);

      validatorImpedimento = false;
    } else {
      if (beginDate > initUseDate) {
        setInitUseDateValidator(true);
        validatorImpedimento = false;
      } else {
        setInitUseDateValidator(false);
      }
    }

    if (finishUseDate === undefined) {
      setFinishUseDateValidator(true);

      validatorImpedimento = false;
    } else {
      if (initUseDate > finishUseDate) {
        setFinishUseDateValidator(true);
        validatorImpedimento = false;
      } else {
        setFinishUseDateValidator(false);
      }
    }

    if (checkDays() === false || checkDays() === undefined) {
      setWeekDaysValidator(true);
      validatorImpedimento = false;
    } else {
      setWeekDaysValidator(false);
    }

    const arenaValidate = arenas.filter((item) => item.checked).length === 0;
    if (arenaValidate) {
      setArenasValidator(true);
      validatorImpedimento = false;
    } else {
      setArenasValidator(false);
    }

    if (oneUserCheck === true) {
      if (phone === "" && nickname === "") {
        setNicknameValidator(true);
        setPhoneValidator(true);
        validatorImpedimento = false;
      } else {
        setNicknameValidator(false);
        setPhoneValidator(false);
      }
    }

    if (priceDiscountCheck === true) {
      if (price === 0 || price === "" || price === undefined) {
        setPriceValidator(true);
        validatorImpedimento = false;
      } else {
        setPriceValidator(false);
      }
    }

    if (percentageDiscountCheck === true) {
      if (price === 0 || price === "" || price === undefined) {
        setPriceValidator(true);
        validatorImpedimento = false;
      } else {
        setPriceValidator(false);
      }
    }

    if (limitedCheck === true && (limit === "0" || limit === "")) {
      setLimitValidator(true);
      validatorImpedimento = false;
    } else {
      setLimitValidator(false);
    }

    if (
      churrasqueiraCheck === false &&
      areiaCheck === false &&
      societyCheck === false
    ) {
      setSpaceTypeValidator(true);
      validatorImpedimento = false;
    } else {
      setSpaceTypeValidator(false);
    }

    return validatorImpedimento;
  }

  async function handleClickCancel() {
    history.push("/coupons");
  }
  async function handleClickCreateCoupon() {
    setIsClickSaveCoupon(false); // trava o botão de save para o usuário não clicar diversas vezes
    const token = localStorage.getItem("token");
    const object = JSON.parse(token);

    let accessToken = "";
    if (object.accessToken) {
      accessToken = object.accessToken;
    }

    let validador = true;
    validador = await dataValidator();
    if (validador === false) {
      toast.error("Campos em vermelho precisam ser preenchidos corretamente");
      setIsClickSaveCoupon(true); // libera o botão para o usuário clicar novamente
      return;
    } else {
      let completPhone = countryNumber + phone;
      completPhone = completPhone.replace(/\D/g, "");
      completPhone = "+" + completPhone;

      const limitCustom = ilimitedCheck === true ? null : limit; // TODO - VALIDAR COM BACKEND, DEVE RECEBER LIMIT = NULL
      try {
        let coupon = {
          CouponCode: couponName,
          CategoryID: categoryID,
          BeginDate: beginDate,
          ExpirationDate: expirationDate,
          InitUseDate: initUseDate,
          FinishUseDate: finishUseDate,
          Weekdays: organizeDays(),
          ArenaIDs: organizeArenas(),
          AllUsers: allUserCheck,
          UserData: isTelefone == userFormId ? completPhone : nickname, // tratar pelo telefone
          BookingType: bookingTypeO == true ? "O" : "R",
          AllBookings: allBookings,
          Limit: limitCustom,
          DiscountType:
            percentageDiscountCheck == true
              ? percentageDiscountID
              : priceDiscountID,
          Value:
            percentageDiscountCheck === true
              ? price
              : price.replace(/[.]/g, "").replace(",", "."),
          SpaceType: organizeSpaceTypes(),
        };

        let accessToken = "";
        if (object.accessToken) {
          accessToken = object.accessToken;
        }

        handleClickLoader(true);

        const response = await api.post("api/suporte/CreateCoupon", coupon, {
          headers: { Authorization: "Bearer " + accessToken },
        });

        if (response.data?.error) {
          toast.error(response.data?.message);
        }

        if (response.data?.couponValidate) {
          setCouponNameValidator(true);
          setCouponNameReturn(response.data?.couponValidateMessage);
          setCouponNameValidateReturn(response.data?.couponValidate);
        }

        setUserValidateMessage(response.data?.userValidateMessage);
        if (response.data?.userValidate) {
          const userValidate = response.data?.userValidateMessage.toString();

          const isNickname = userValidate.includes("Nickname");

          if (isNickname) {
            setNicknameReturn(response.data?.userValidate);
            setNicknameValidator(true);
          } else {
            setPhoneReturn(response.data?.userValidate);
            setPhoneValidator(true);
          }
        }

        handleClickLoader();

        if (
          response.data?.error == false &&
          response.data?.couponValidate == false &&
          response.data?.userValidate == false
        ) {
          toast.success("Cupom salvo.");
          history.push("/coupons");
        }

        setIsClickSaveCoupon(true); // libera o botão para o usuário clicar novamente
      } catch (error) {
        console.log("error ", error);

        setIsClickSaveCoupon(true); // libera o botão para o usuário clicar novamente

        handleClickLoader();
      }
    }
  }

  const organizeArenas = () => {
    let arena = "";

    arenas.map((item) => {
      if (item.checked) {
        arena += item.id + ",";
      }
    });

    if (arena.split(",").length === 2) {
      arena = arena.replace(",", "");
    }

    return arena;
  };

  const organizeSpaceTypes = () => {
    let spaces = "";

    if (societyCheck === true) {
      spaces += societySpaceID + ",";
    }

    if (areiaCheck === true) {
      spaces += areiaSpaceID + ",";
    }

    if (churrasqueiraCheck === true) {
      spaces += churrasqueiraSpaceID;
    }

    if (spaces.split(",").length === 2) {
      spaces = spaces.replace(",", "");
    }

    return spaces;
  };

  const organizeDays = () => {
    let weekday = "";

    if (mondayCheck === true) {
      weekday += mondayID + ",";
    }

    if (tuesdayCheck === true) {
      weekday += tuesdayID + ",";
    }

    if (wednesdayCheck === true) {
      weekday += wednesdayID + ",";
    }

    if (thursdayCheck === true) {
      weekday += thursdayID + ",";
    }

    if (fridayCheck === true) {
      weekday += fridayID + ",";
    }

    if (saturdayCheck === true) {
      weekday += saturdayID + ",";
    }

    if (sundayCheck === true) {
      weekday += sundayID;
    }

    if (weekday.split(",").length === 2) {
      weekday = weekday.replace(",", "");
    }

    return weekday;
  };
  const validate = () => {
    const verify = accessValidator("billing");

    if (verify === false) {
      toast.info(
        "Seu usuário não tem permissões para esse sistema, contate o suporte"
      );
      history.push("/home");
    }
  };

  const getFormsUser = async () => {
    try {
      const token = localStorage.getItem("token");
      const object = JSON.parse(token);

      let accessToken = "";
      if (object.accessToken) {
        accessToken = object.accessToken;
      }

      const response = await api.get(`api/suporte/GetFormsUser`, {
        headers: { Authorization: "Bearer " + accessToken },
      });

      const getTelefone = response.data.list.filter(
        (item) => item.description === "Telefone"
      );
      setIsTelefone(getTelefone[0].id);

      setUserForms(response.data.list);
    } catch (error) {
      console.log("getFormsUser ", error);
      setUserForms([]);
    }
  };

  const getArena = async () => {
    try {
      const token = localStorage.getItem("token");
      const object = JSON.parse(token);
      let accessToken = "";
      if (object.accessToken) {
        accessToken = object.accessToken;
      }

      const response = await api.get(`api/suporte/GetCouponArenas`, {
        headers: { Authorization: "Bearer " + accessToken },
      });

      const newArenas = response.data.list.map((item) => {
        return {
          ...item,
          checked: false,
        };
      });
      setArenas(newArenas);
    } catch (error) {
      console.log("getArena ", error);
      setArenas([]);
    }
  };

  const getCategorys = async () => {
    const token = localStorage.getItem("token");
    const object = JSON.parse(token);
    let accessToken = "";
    if (object.accessToken) {
      accessToken = object.accessToken;
    }

    try {
      const response = await api.get(`api/suporte/GetCouponCategorys`, {
        headers: { Authorization: "Bearer " + accessToken },
      });

      setCategorys(response.data.list);
      setCategoryID(response.data.list[1].value);
    } catch (error) {
      console.log("getCategorys ", error);
      setCategorys([]);
    }
  };

  const handlePaste = (event) => {
    event.preventDefault(); // Impede a ação padrão de colar
    toast.error("Não é permitido Ctrl+V");
  };

  const onClickAllBookings = () => {
    setFirstBooking(false);
    setAllBookings(true);
  };

  const onClickFirstBooking = () => {
    setFirstBooking(true);
    setAllBookings(false);
  };

  const onClickAvulso = () => {
    setBookingTypeO(true);
    setBookingTypeR(false);
    onClickFirstBooking();
  };

  const onClickMensal = () => {
    setBookingTypeO(false);
    setBookingTypeR(true);
  };

  const checkSpaces = () => {
    if (societyCheck || areiaCheck || churrasqueiraCheck) return true;
  };

  const onClickAllSpaces = () => {
    setSocietyCheck(true);
    setAreiaCheck(true);
    setChurrasqueiraCheck(true);
  };

  const onClickRemoveAllSpaces = () => {
    setSocietyCheck(false);
    setAreiaCheck(false);
    setChurrasqueiraCheck(false);
  };

  const checkDays = () => {
    if (
      mondayCheck ||
      tuesdayCheck ||
      wednesdayCheck ||
      thursdayCheck ||
      fridayCheck ||
      saturdayCheck ||
      sundayCheck
    )
      return true;
  };

  const onClickAllChecks = () => {
    setMondayCheck(true);
    setTuesdayCheck(true);
    setWednesdayCheck(true);
    setThursdayCheck(true);
    setFridayCheck(true);
    setSaturdayCheck(true);
    setSundayCheck(true);
  };

  const onClickRemoveAllChecks = () => {
    setMondayCheck(false);
    setTuesdayCheck(false);
    setWednesdayCheck(false);
    setThursdayCheck(false);
    setFridayCheck(false);
    setSaturdayCheck(false);
    setSundayCheck(false);
  };

  const onClickAllArenas = () => {
    const newArenas = arenas.map((item) => {
      return {
        ...item,
        checked: true,
      };
    });

    setArenas(newArenas);
  };

  const onClickRemoveAllArenas = () => {
    const newArenas = arenas.map((item) => {
      return {
        ...item,
        checked: false,
      };
    });

    setArenas(newArenas);
  };

  const onClickCheckChurrasqueira = () => {
    setChurrasqueiraCheck(!churrasqueiraCheck);
  };

  const onClickPriceDiscount = () => {
    setPriceDiscountCheck(true);
    setPercentageDiscountCheck(false);
    setPrice(0);

    setSocietyCheck(false);
    setAreiaCheck(false);
    setChurrasqueiraCheck(false);
  };

  const onClickPercentageDiscount = () => {
    setPriceDiscountCheck(false);
    setPercentageDiscountCheck(true);
    setPrice(1);

    setSocietyCheck(false);
    setAreiaCheck(false);
    setChurrasqueiraCheck(false);
  };

  const onClickIlimited = () => {
    setIlimitedCheck(true);
    setLimitedCheck(false);
  };

  const onClickLimited = () => {
    setIlimitedCheck(false);
    setLimitedCheck(true);
  };

  const onClickOneUser = () => {
    setOneUserCheck(true);
    setAllUserCheck(false);
  };

  const onClickAllUsers = () => {
    setOneUserCheck(false);
    setAllUserCheck(true);
    setUserFormId(1);
    setCountryNumber("+55");
    setPhone("");
    setNickname("");
  };

  const onClickCheckAreia = () => {
    setAreiaCheck(!areiaCheck);
  };

  const onClickCheckSociety = () => {
    setSocietyCheck(!societyCheck);
  };

  const onClickRadioChurrasqueira = () => {
    setSocietyCheck(false);
    setAreiaCheck(false);
    setChurrasqueiraCheck(true);
  };

  const onClickRadioAreia = () => {
    setSocietyCheck(false);
    setAreiaCheck(true);
    setChurrasqueiraCheck(false);
  };

  const onClickRadioSociety = () => {
    setSocietyCheck(true);
    setAreiaCheck(false);
    setChurrasqueiraCheck(false);
  };

  const onClickCheckMonday = () => {
    setMondayCheck(!mondayCheck);
  };

  const onClickCheckTuesday = () => {
    setTuesdayCheck(!tuesdayCheck);
  };

  const onClickCheckWednesday = () => {
    setWednesdayCheck(!wednesdayCheck);
  };

  const onClickCheckThursday = () => {
    setThursdayCheck(!thursdayCheck);
  };

  const onClickCheckFriday = () => {
    setFridayCheck(!fridayCheck);
  };

  const onClickCheckSaturday = () => {
    setSaturdayCheck(!saturdayCheck);
  };

  const onClickCheckSunday = () => {
    setSundayCheck(!sundayCheck);
  };

  const onClickCheckArena = (arenaId) => {
    const newArenas = arenas.map((item) => {
      return {
        ...item,
        checked: arenaId === item.id ? !item.checked : item.checked,
      };
    });

    setArenas(newArenas);
  };

  const validateNickname = (newNickname) => {
    if (newNickname.length <= 50) {
      //limite maximo do nome de um nickname é de 50 caracteres
      if (!/\s/.test(newNickname)) {
        // Se não contém espaços em branco, atualize o estado
        setNickname(newNickname);
      }
    }
  };

  const validateCoupon = (newCoupon) => {
    if (newCoupon.length <= 25) {
      //limite maximo do nome de um cupom é de 25 caracteres
      if (!/\s/.test(newCoupon)) {
        // Converter para maiúsculas
        newCoupon = newCoupon.toUpperCase();
        // Atualizar o estado
        setCouponName(newCoupon);
      }
    }
  };

  const getYesterday = () => {
    const yesterday = new Date();
    const minDate = yesterday.toISOString().split("T")[0]; // Formata para o formato YYYY-MM-DD

    setMinBeginDate(minDate);
  };

  const onClickBeginDate = (date) => {
    setBeginDate(date);
    setMinExpirationDate(date);
    setMinInitUseDate(date);
  };

  const onClickExpirationDate = (date) => {
    setExpirationDate(date);
  };

  const onClickInitUseDate = (date) => {
    setInitUseDate(date);
    setMinFinishUseDate(date);
  };

  const onClickFinishUseDate = (date) => {
    setFinishUseDate(date);
  };

  const onClickFormUser = (user) => {
    if (user == isTelefone) {
      setPhone("");
      setCountryNumber("+55");
    } else {
      setNickname("");
    }
    setUserFormId(user);
  };

  const getCountries = async () => {
    try {
      const token = localStorage.getItem("token");
      const object = JSON.parse(token);
      let accessToken = "";
      if (object.accessToken) {
        accessToken = object.accessToken;
      }

      const response = await api.get(`api/utils/GetCountry/PT`, {
        headers: { Authorization: "Bearer " + accessToken },
      });

      let lista = response.data.list;
      const index = lista.findIndex((item) => item.ddi === "+55"); // Encontre o índice do objeto com ddi = "+55"

      if (index !== -1) {
        // Se o índice for diferente de -1 (ou seja, encontrado), mova-o para a primeira posição
        const item = lista.splice(index, 1)[0]; // Remove o item da lista
        lista.unshift(item); // Adiciona o item no início da lista
      }

      setCountries(lista);
      setCountryNumber("+55");
    } catch (error) {
      console.log("getCountries ", error);
      setCountries([]);
    }
  };

  useEffect(() => {
    validate();

    // verificado dessa tela
    getCategorys();
    getArena();
    getFormsUser();
    getYesterday();

    const currentDate = new Date();
    setToday(currentDate.toISOString().substring(0, 10));
  }, []);

  return (
    <section>
      <div className="container ">
        <div className="row justify-content-center ">
          <div className="col-12 col-md-12 col-lg-8 col-xl-7 text-center ">
            <div className="col">
              <p className="text-title-Appito justify-content-start pt-4 tracking-in-expand ">
                Criar cupom
              </p>
            </div>

            <div className="row mt-2">
              <div className="mt-1 col-6">
                <div>
                  <label className="title-description">Nome</label>
                  <input
                    type="text"
                    className={`form-control
                    ${
                      couponNameValidator === false
                        ? "input-appito"
                        : "input-appito-error"
                    }
                    scale-in-center `}
                    placeholder="Nome do cupom"
                    value={couponName}
                    onChange={(event) => validateCoupon(event.target.value)}
                  />
                </div>
                {couponNameValidateReturn === true ? (
                  <label className="coupon-message-wrong">
                    <span className="">{couponNameReturn}</span>
                  </label>
                ) : (
                  <div></div>
                )}
              </div>

              <div className="mt-1 col-6 ">
                <div className="col">
                  <label className="title-description">Categoria</label>
                  <select
                    type="text"
                    className={`form-control form-select input-appito scale-in-center 
                    ${
                      categoryIDValidator === false
                        ? "input-appito"
                        : "input-appito-error"
                    }`}
                    placeholder="Categoria"
                    value={categoryID}
                    onChange={(event) => setCategoryID(event.target.value)}
                  >
                    {categorys.map((bank, index) => (
                      <option key={index} value={bank.id}>
                        {bank.description}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className=" mt-4 mb-4 divisor-coupon" />

              <div className="coupon-line">
                <div className="row coupon-date">
                  <label className="title-description">
                    Quando o cupom pode ser utilizado
                  </label>
                  <div className="col-6">
                    <input
                      type="date"
                      className={`form-control input-appito scale-in-center 
                        ${
                          beginDateValidator === false
                            ? "input-appito"
                            : "input-appito-error"
                        }`}
                      placeholder="Inicio"
                      value={beginDate}
                      min={minBeginDate}
                      onPaste={handlePaste}
                      onChange={(event) => onClickBeginDate(event.target.value)}
                    />
                  </div>
                  <div className="col-6">
                    <input
                      type="date"
                      className={`form-control input-appito scale-in-center                       
                        ${
                          expirationDateValidator === false
                            ? "input-appito"
                            : "input-appito-error"
                        }`}
                      placeholder="Fim"
                      value={expirationDate}
                      min={minExpirationDate}
                      onPaste={handlePaste}
                      onChange={(event) =>
                        onClickExpirationDate(event.target.value)
                      }
                    />
                  </div>
                </div>

                <div className="row coupon-date">
                  <label className="title-description">
                    Quando o desconto pode ser aplicado
                  </label>
                  <div className="col-6">
                    <input
                      type="date"
                      className={`form-control input-appito scale-in-center 
                      ${
                        initUseDateValidator === false
                          ? "input-appito"
                          : "input-appito-error"
                      }`}
                      placeholder="Inicio"
                      value={initUseDate}
                      min={minInitUseDate}
                      onPaste={handlePaste}
                      onChange={(event) =>
                        onClickInitUseDate(event.target.value)
                      }
                    />
                  </div>
                  <div className="col-6">
                    <input
                      type="date"
                      className={`form-control input-appito scale-in-center 
                      ${
                        finishUseDateValidator === false
                          ? "input-appito"
                          : "input-appito-error"
                      }
                      `}
                      placeholder="Fim"
                      value={finishUseDate}
                      min={minFinishUseDate}
                      onPaste={handlePaste}
                      onChange={(event) =>
                        onClickFinishUseDate(event.target.value)
                      }
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className=" mt-4 mb-4 divisor-coupon" />

            <div>
              <label className="title-description">
                Dias da semana que podem receber o desconto
                {checkDays() === true ? (
                  <span
                    className="check-all"
                    onClick={() => onClickRemoveAllChecks()}
                  >
                    Desmarcar todos
                  </span>
                ) : (
                  <span
                    className="check-all"
                    onClick={() => onClickAllChecks()}
                  >
                    Marcar todos
                  </span>
                )}
              </label>

              <div className="coupon-day">
                <div className="coupon-check">
                  <input
                    style={{
                      backgroundColor: mondayCheck ? "#173016 " : "",
                      border: "2px solid #494C45",
                      borderRadius: "2px",
                    }}
                    className="form-check-input "
                    type="checkbox"
                    value=""
                    checked={mondayCheck}
                    id={mondayID}
                    onChange={() => onClickCheckMonday()}
                  />
                  <div className="coupon-weekdays">Seg</div>
                </div>

                <div className="coupon-check">
                  <input
                    style={{
                      backgroundColor: tuesdayCheck ? "#173016 " : "",
                      border: "2px solid #494C45",
                      borderRadius: "2px",
                    }}
                    className="form-check-input "
                    type="checkbox"
                    value=""
                    checked={tuesdayCheck}
                    id={tuesdayID}
                    onChange={() => onClickCheckTuesday()}
                  />
                  <div className="coupon-weekdays">Ter</div>
                </div>

                <div className="coupon-check">
                  <input
                    style={{
                      backgroundColor: wednesdayCheck ? "#173016 " : "",
                      border: "2px solid #494C45",
                      borderRadius: "2px",
                    }}
                    className="form-check-input "
                    type="checkbox"
                    value=""
                    checked={wednesdayCheck}
                    id={wednesdayID}
                    onChange={() => onClickCheckWednesday()}
                  />
                  <div className="coupon-weekdays">Qua</div>
                </div>

                <div className="coupon-check">
                  <input
                    style={{
                      backgroundColor: thursdayCheck ? "#173016 " : "",
                      border: "2px solid #494C45",
                      borderRadius: "2px",
                    }}
                    className="form-check-input "
                    type="checkbox"
                    value=""
                    checked={thursdayCheck}
                    id={thursdayID}
                    onChange={() => onClickCheckThursday()}
                  />
                  <div className="coupon-weekdays">Qui</div>
                </div>

                <div className="coupon-check">
                  <input
                    style={{
                      backgroundColor: fridayCheck ? "#173016 " : "",
                      border: "2px solid #494C45",
                      borderRadius: "2px",
                    }}
                    className="form-check-input "
                    type="checkbox"
                    value=""
                    checked={fridayCheck}
                    id={fridayID}
                    onChange={() => onClickCheckFriday()}
                  />
                  <div className="coupon-weekdays">Sex</div>
                </div>

                <div className="coupon-check">
                  <input
                    style={{
                      backgroundColor: saturdayCheck ? "#173016 " : "",
                      border: "2px solid #494C45",
                      borderRadius: "2px",
                    }}
                    className="form-check-input "
                    type="checkbox"
                    value=""
                    checked={saturdayCheck}
                    id={saturdayID}
                    onChange={() => onClickCheckSaturday()}
                  />
                  <div className="coupon-weekdays">Sab</div>
                </div>

                <div className="coupon-check">
                  <input
                    style={{
                      backgroundColor: sundayCheck ? "#173016 " : "",
                      border: "2px solid #494C45",
                      borderRadius: "2px",
                    }}
                    className="form-check-input "
                    type="checkbox"
                    value=""
                    checked={sundayCheck}
                    id={sundayID}
                    onChange={() => onClickCheckSunday()}
                  />
                  <div className="coupon-weekdays">Dom</div>
                </div>
              </div>

              {weekDaysValidator === true ? (
                <label className="coupon-message-wrong">
                  <span className="">Selecione ao menos 1 opção</span>
                </label>
              ) : (
                <div></div>
              )}
            </div>
            <div className=" mt-4 mb-4 divisor-coupon" />

            <div>
              {arenas.filter((item) => item.checked).length === 0 && (
                <label className="title-description">
                  Arena/Unidade
                  <span
                    className="check-all"
                    onClick={() => onClickAllArenas()}
                  >
                    Marcar todos
                  </span>
                </label>
              )}

              <div className="title-description">
                {arenas.filter((item) => item.checked).length > 0 && (
                  <label className="title-description">
                    Arena/Unidade
                    <span
                      className="check-all"
                      onClick={() => onClickRemoveAllArenas()}
                    >
                      Desmarcar todos
                    </span>
                  </label>
                )}
              </div>

              <div className="coupon-day ">
                {arenas.map((arena) => (
                  <div className="coupon-arena-list" key={arena?.id}>
                    <input
                      style={{
                        backgroundColor: arena?.checked ? "#173016 " : "",
                        border: "2px solid #494C45",
                        borderRadius: "2px",
                      }}
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      checked={arena?.checked}
                      id={arena?.description}
                      onChange={() => onClickCheckArena(arena?.id)}
                    />
                    <div className="coupon-desc">{arena.description}</div>
                  </div>
                ))}
              </div>

              {arenasValidator === true ? (
                <label className="coupon-message-wrong">
                  <span className="">Selecione ao menos 1 opção</span>
                </label>
              ) : (
                <div></div>
              )}
            </div>
            <div className=" mt-4 mb-4 divisor-coupon" />

            <div>
              <div>
                <div className="coupon-day col-12">
                  <div
                    className={`
                      ${
                        userFormId == isTelefone
                          ? "choice-phone"
                          : "choice-nickname"
                      }`}
                  >
                    <label className="title-description">Usuário</label>
                    <div className={`user-radio`}>
                      <div
                        className="coupon-user"
                        style={{ paddingRight: "30px" }}
                      >
                        <input
                          style={{
                            backgroundColor: allUserCheck ? "#173016 " : "",
                            border: "2px solid #494C45",
                          }}
                          className="form-check-input"
                          type="radio"
                          value=""
                          checked={allUserCheck}
                          id={allUserId}
                          onChange={() => onClickAllUsers()}
                        />
                        <div className="coupon-desc">Todos</div>
                      </div>

                      <div className="coupon-user">
                        <input
                          style={{
                            backgroundColor: oneUserCheck ? "#173016 " : "",
                            border: "2px solid #494C45",
                          }}
                          className="form-check-input"
                          type="radio"
                          value=""
                          checked={oneUserCheck}
                          id={oneUserId}
                          onChange={() => onClickOneUser()}
                        />
                        <div className="coupon-desc">Específico</div>
                      </div>
                    </div>
                  </div>

                  <div className="" style={{ width: "55%" }}>
                    <div>
                      <div>
                        {allUserCheck === true ? (
                          <div className="col-12">
                            <label
                              className={`
                                  ${
                                    allUserCheck === true
                                      ? "title-description-disabled"
                                      : "title-description"
                                  }
                              `}
                            >
                              Nickname ou telefone
                            </label>
                            <div className="coupon-day">
                              <div className="col">
                                <select
                                  type="text"
                                  className={`form-control form-select input-appito scale-in-center `}
                                  value={userFormId}
                                  onChange={(event) =>
                                    onClickFormUser(event.target.value)
                                  }
                                  disabled
                                >
                                  {userForms.map((form, index) => (
                                    <option key={index} value={form.id}>
                                      {form.description}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              {userFormId === isTelefone ? (
                                <div
                                  className="col-7"
                                  style={{ paddingLeft: "10px" }}
                                >
                                  <div className="col">
                                    <input
                                      type="text"
                                      className={`form-control 
                                             ${
                                               phoneValidator === false
                                                 ? "input-appito"
                                                 : "input-appito-error"
                                             }
                                            scale-in-center `}
                                      placeholder="Telefone"
                                      value={phone}
                                      onChange={(event) =>
                                        removeCharsPhone(event.target.value)
                                      }
                                      disabled
                                    />
                                  </div>
                                </div>
                              ) : (
                                <div
                                  className="col-7"
                                  style={{ paddingLeft: "10px" }}
                                >
                                  <div className="">
                                    <input
                                      type="text"
                                      className={`form-control 
                                             ${
                                               nicknameValidator === false
                                                 ? "input-appito"
                                                 : "input-appito-error"
                                             }
                                            scale-in-center `}
                                      placeholder="Nickname"
                                      value={nickname}
                                      onChange={(event) =>
                                        validateNickname(event.target.value)
                                      }
                                      disabled
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        ) : (
                          <div className="col-12">
                            <label
                              className={`
                                  ${
                                    allUserCheck === true
                                      ? "title-description-disabled"
                                      : "title-description"
                                  }
                              `}
                            >
                              Nickname ou telefone
                            </label>
                            <div className="coupon-day">
                              <div className="col">
                                <select
                                  type="text"
                                  className={`form-control form-select input-appito scale-in-center `}
                                  value={userFormId}
                                  onChange={(event) =>
                                    onClickFormUser(event.target.value)
                                  }
                                >
                                  {userForms.map((form, index) => (
                                    <option key={index} value={form.id}>
                                      {form.description}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              {userFormId == isTelefone ? (
                                <div
                                  className="col-8"
                                  style={{
                                    paddingLeft: "8px",
                                    display: "flex",
                                  }}
                                >
                                  <div
                                    className=""
                                    style={{
                                      width: "40%",
                                    }}
                                  >
                                    <div className="col">
                                      <input
                                        type="text"
                                        className={`form-control 
                                            ${
                                              phoneValidator === false
                                                ? "input-appito"
                                                : "input-appito-error"
                                            }
                                            scale-in-center `}
                                        placeholder="+55"
                                        value={countryNumber}
                                        onChange={(event) =>
                                          setCountryNumber(event.target.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div
                                    className=""
                                    style={{ paddingLeft: "8px" }}
                                  >
                                    <input
                                      type="text"
                                      className={`form-control 
                                               ${
                                                 phoneValidator === false
                                                   ? "input-appito"
                                                   : "input-appito-error"
                                               }
                                              scale-in-center `}
                                      placeholder="Telefone"
                                      value={phone}
                                      onChange={(event) =>
                                        removeCharsPhone(event.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                              ) : (
                                <div
                                  className="col-7"
                                  style={{
                                    paddingLeft: "10px",
                                  }}
                                >
                                  <div className="">
                                    <input
                                      type="text"
                                      className={`form-control 
                                               ${
                                                 nicknameValidator === false
                                                   ? "input-appito"
                                                   : "input-appito-error"
                                               }
                                              scale-in-center `}
                                      placeholder="Nickname"
                                      value={nickname}
                                      onChange={(event) =>
                                        validateNickname(event.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                            {nicknameReturn === true ? (
                              <label className="coupon-message-wrong">
                                <span className="">{userValidateMessage}</span>
                              </label>
                            ) : (
                              <div></div>
                            )}
                            {phoneReturn === true ? (
                              <label className="coupon-message-wrong">
                                <span className="">{userValidateMessage}</span>
                              </label>
                            ) : (
                              <div></div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className=" mt-4 mb-4 divisor-coupon" />

            <div>
              <div>
                <div className="coupon-day">
                  <div
                    className="coupon-day col-12"
                    style={{
                      marginTop: "10px",
                    }}
                  >
                    <div className="col-6">
                      <label className="title-description">
                        Tipo de reserva
                      </label>
                      <div className="coupon-day">
                        <div className="coupon-radio-booking-type">
                          <input
                            style={{
                              backgroundColor: bookingTypeO ? "#173016 " : "",
                              border: "2px solid #494C45",
                            }}
                            className="form-check-input"
                            type="radio"
                            value=""
                            checked={bookingTypeO}
                            id="O"
                            onChange={() => onClickAvulso()}
                          />
                          <div className="coupon-desc">Avulso</div>
                        </div>

                        <div className="coupon-radio-booking-type ">
                          <input
                            style={{
                              backgroundColor: bookingTypeR ? "#173016 " : "",
                              border: "2px solid #494C45",
                            }}
                            className="form-check-input"
                            type="radio"
                            value=""
                            checked={bookingTypeR}
                            id="R"
                            onChange={() => onClickMensal()}
                          />
                          <div className="coupon-desc">Mensal</div>
                        </div>
                      </div>
                    </div>

                    <div className="col-6">
                      <label
                        className={`
                          ${
                            bookingTypeO === true
                              ? "title-description-disabled"
                              : "title-description"
                          }
                            `}
                      >
                        Mensal, aplicar
                      </label>
                      <div className="coupon-day">
                        {bookingTypeO === true ? (
                          <div className="coupon-radio-booking-type-choice">
                            <input
                              style={{
                                backgroundColor: firstBooking ? "#173016 " : "",
                                border: "2px solid #494C45",
                              }}
                              className="form-check-input"
                              type="radio"
                              value=""
                              checked={firstBooking}
                              id={1}
                              onChange={() => onClickFirstBooking()}
                              disabled
                            />
                            <div
                              className={`
                                ${
                                  bookingTypeO === true
                                    ? "title-description-disabled"
                                    : "title-description"
                                }
                                coupon-desc`}
                            >
                              Só na 1°
                            </div>
                          </div>
                        ) : (
                          <div className="coupon-radio-booking-type-choice">
                            <input
                              style={{
                                backgroundColor: firstBooking ? "#173016 " : "",
                                border: "2px solid #494C45",
                              }}
                              className="form-check-input"
                              type="radio"
                              value=""
                              checked={firstBooking}
                              id={1}
                              onChange={() => onClickFirstBooking()}
                            />
                            <div
                              className={`
                                ${
                                  bookingTypeO === true
                                    ? "title-description-disabled"
                                    : "title-description"
                                }
                                coupon-desc`}
                            >
                              Só na 1°
                            </div>
                          </div>
                        )}
                        {bookingTypeO === true ? (
                          <div className="coupon-radio-booking-type-choice">
                            <input
                              style={{
                                backgroundColor: allBookings ? "#173016 " : "",
                                border: "2px solid #494C45",
                              }}
                              className="form-check-input"
                              type="radio"
                              value=""
                              checked={allBookings}
                              id={1000}
                              onChange={() => onClickAllBookings()}
                              disabled
                            />
                            <div
                              className={`
                                ${
                                  bookingTypeO === true
                                    ? "title-description-disabled"
                                    : "title-description"
                                }
                                coupon-desc`}
                            >
                              Em todas
                            </div>
                          </div>
                        ) : (
                          <div className="coupon-radio-booking-type-choice">
                            <input
                              style={{
                                backgroundColor: allBookings ? "#173016 " : "",
                                border: "2px solid #494C45",
                              }}
                              className="form-check-input"
                              type="radio"
                              value=""
                              checked={allBookings}
                              id={1000}
                              onChange={() => onClickAllBookings()}
                            />
                            <div
                              className={`
                                ${
                                  bookingTypeO === true
                                    ? "title-description-disabled"
                                    : "title-description"
                                }
                                coupon-desc`}
                            >
                              Em todas
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className=" mt-4 mb-4 divisor-coupon" />

            <div>
              <div>
                <div className="coupon-day col-12">
                  <div className="col-6">
                    <label className="title-description">
                      Qtd. Máxima de uso:
                    </label>
                    <div className="coupon-day col-6">
                      <div className="coupon-radio-limits">
                        <input
                          style={{
                            backgroundColor: ilimitedCheck ? "#173016 " : "",
                            border: "2px solid #494C45",
                          }}
                          className="form-check-input"
                          type="radio"
                          value=""
                          checked={ilimitedCheck}
                          id={ilimitedID}
                          onChange={() => onClickIlimited()}
                        />
                        <div className="coupon-desc">Ilimitado</div>
                      </div>

                      <div className="coupon-radio-limits">
                        <input
                          style={{
                            backgroundColor: limitedCheck ? "#173016 " : "",
                            border: "2px solid #494C45",
                          }}
                          className="form-check-input"
                          type="radio"
                          value=""
                          checked={limitedCheck}
                          id={limitedID}
                          onChange={() => onClickLimited()}
                        />
                        <div className="coupon-desc">Limitado</div>
                      </div>
                    </div>
                  </div>

                  <div className="col-6">
                    <div>
                      <div>
                        {ilimitedCheck === true ? (
                          <div>
                            <label
                              className={`
                                ${
                                  ilimitedCheck === true
                                    ? "title-description-disabled"
                                    : "title-description"
                                }
                            `}
                            >
                              Limite de uso
                            </label>
                            <input
                              type="number"
                              className={`form-control input-appito scale-in-center `}
                              placeholder="0"
                              value={limit}
                              onChange={(event) => {
                                setLimit(event.target.value);
                              }}
                              min="1"
                              disabled
                            />
                          </div>
                        ) : (
                          <div>
                            <label className="title-description">
                              Limite de uso
                            </label>
                            <input
                              type="number"
                              className={`form-control 
                              ${
                                limitValidator === false
                                  ? "input-appito"
                                  : "input-appito-error"
                              }
                             scale-in-center `}
                              placeholder="0"
                              value={limit}
                              onChange={(event) => {
                                const newValue = event.target.value.replace(
                                  /\D/,
                                  ""
                                );
                                if (newValue >= 0) {
                                  setLimit(newValue);
                                }
                              }}
                              min="1"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className=" mt-4 mb-4 divisor-coupon" />

            <div>
              <div className="coupon-day col-12">
                <div className="col-6">
                  <label className="title-description">Tipo de desconto</label>
                  <div className="coupon-day">
                    <div className="coupon-radio-discount">
                      <input
                        style={{
                          backgroundColor: priceDiscountCheck ? "#173016" : "",
                          border: "2px solid #494C45",
                        }}
                        className="form-check-input"
                        type="radio"
                        value=""
                        checked={priceDiscountCheck}
                        id={priceDiscountID}
                        onChange={() => onClickPriceDiscount()}
                      />
                      <div className="coupon-desc">Valor</div>
                    </div>

                    <div className="coupon-radio-discount">
                      <input
                        style={{
                          backgroundColor: percentageDiscountCheck
                            ? "#173016 "
                            : "",
                          border: "2px solid #494C45",
                        }}
                        className="form-check-input"
                        type="radio"
                        value=""
                        checked={percentageDiscountCheck}
                        id={percentageDiscountID}
                        onChange={() => onClickPercentageDiscount()}
                      />
                      <div className="coupon-desc">Porcentagem</div>
                    </div>
                  </div>
                </div>

                <div className="col-6">
                  <div>
                    {percentageDiscountCheck === true ? (
                      <div>
                        <label className="title-description">
                          Porcentagem(%)
                        </label>
                        <input
                          type="number"
                          className={`form-control 
                        ${
                          priceValidator === false
                            ? "input-appito"
                            : "input-appito-error"
                        }
                        scale-in-center `}
                          placeholder={
                            percentageDiscountCheck === true ? "0" : "0,00"
                          }
                          value={price}
                          onChange={(event) => {
                            const newValue = event.target.value;
                            if (newValue >= 0 && newValue <= 70) {
                              setPrice(newValue);
                            }
                          }}
                          min="1"
                          max="70"
                        />
                      </div>
                    ) : (
                      <div>
                        <label className="title-description">Valor(R$)</label>
                        <input
                          type="text"
                          className={`form-control 
                          ${
                            priceValidator === false
                              ? "input-appito"
                              : "input-appito-error"
                          }
                          scale-in-center `}
                          placeholder={
                            percentageDiscountCheck === true ? "0" : "0,00"
                          }
                          value={price}
                          onChange={(event) =>
                            removeCharsPrice(event.target.value)
                          }
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className=" mt-4 mb-4 divisor-coupon" />

            {priceDiscountCheck === true ? (
              <div>
                <div className="title-description">
                  <label>Tipo de espaço</label>
                </div>
              </div>
            ) : (
              <div className="title-description">
                <label>Tipo de espaço</label>
                {checkSpaces() === true ? (
                  <span
                    className="check-all"
                    onClick={() => onClickRemoveAllSpaces()}
                  >
                    Desmarcar todos
                  </span>
                ) : (
                  <span
                    className="check-all"
                    onClick={() => onClickAllSpaces()}
                  >
                    Marcar todos
                  </span>
                )}
              </div>
            )}

            {priceDiscountCheck === true ? (
              <div className="coupon-day">
                <div className="space-list">
                  <input
                    style={{
                      backgroundColor: societyCheck ? "#173016 " : "",
                      border: "2px solid #494C45",
                    }}
                    className="form-check-input"
                    type="radio"
                    value=""
                    checked={societyCheck}
                    id={societySpaceID}
                    onChange={() => onClickRadioSociety()}
                  />
                  <div className="coupon-desc">Society</div>
                </div>

                <div className="space-list">
                  <input
                    style={{
                      backgroundColor: areiaCheck ? "#173016 " : "",
                      border: "2px solid #494C45",
                    }}
                    className="form-check-input"
                    type="radio"
                    value=""
                    checked={areiaCheck}
                    id={areiaSpaceID}
                    onChange={() => onClickRadioAreia()}
                  />
                  <div className="coupon-desc">Areia</div>
                </div>

                <div className="space-list">
                  <input
                    style={{
                      backgroundColor: churrasqueiraCheck ? "#173016 " : "",
                      border: "2px solid #494C45",
                    }}
                    className="form-check-input"
                    type="radio"
                    value=""
                    checked={churrasqueiraCheck}
                    id={churrasqueiraSpaceID}
                    onChange={() => onClickRadioChurrasqueira()}
                  />
                  <div className="coupon-desc">Churrasqueira</div>
                </div>
              </div>
            ) : (
              <div className="coupon-day">
                <div className="space-list">
                  <input
                    style={{
                      backgroundColor: societyCheck ? "#173016 " : "",
                      border: "2px solid #494C45",
                      borderRadius: "2px",
                    }}
                    className="form-check-input "
                    type="checkbox"
                    value=""
                    checked={societyCheck}
                    id={societySpaceID}
                    onChange={() => onClickCheckSociety()}
                  />
                  <div className="coupon-desc">Society</div>
                </div>

                <div className="space-list">
                  <input
                    style={{
                      backgroundColor: areiaCheck ? "#173016 " : "",
                      border: "2px solid #494C45",
                      borderRadius: "2px",
                    }}
                    className="form-check-input "
                    type="checkbox"
                    value=""
                    checked={areiaCheck}
                    id={areiaSpaceID}
                    onChange={() => onClickCheckAreia()}
                  />
                  <div className="coupon-desc">Areia</div>
                </div>

                <div className="space-list">
                  <input
                    style={{
                      backgroundColor: churrasqueiraCheck ? "#173016 " : "",
                      border: "2px solid #494C45",
                      borderRadius: "2px",
                    }}
                    className="form-check-input "
                    type="checkbox"
                    value=""
                    checked={churrasqueiraCheck}
                    id={churrasqueiraSpaceID}
                    onChange={() => onClickCheckChurrasqueira()}
                  />
                  <div className="coupon-desc">Churrasqueira</div>
                </div>
              </div>
            )}

            {spaceTypeValidator === true ? (
              <label className="coupon-message-wrong">
                <span className="">Selecione ao menos 1 opção</span>
              </label>
            ) : (
              <div></div>
            )}

            <div className=" mt-4 mb-4 divisor-coupon" />

            {/* ACIMA TELA NOVA GABRIEL */}

            <div className="coupon-footer">
              <div>
                <Button
                  text="Voltar"
                  typeButton="secundary"
                  onClick={() => handleClickCancel()}
                  style="btn-sm col-12 scale-in-center"
                />
              </div>

              <div style={{ paddingLeft: "30px" }}>
                {isClickSaveCoupon === true ? (
                  <Button
                    text="Salvar cupom"
                    typeButton="primary"
                    onClick={() => handleClickCreateCoupon()}
                    style="btn-sm col-12 scale-in-center"
                  />
                ) : (
                  <Button
                    text="Salvar cupom"
                    typeButton="primary"
                    onClick={() => handleClickCreateCoupon()}
                    style="btn-sm col-12 scale-in-center"
                    isDisabled={!isClickSaveCoupon}
                  />
                )}
              </div>
            </div>
            <div className="mini-divisor-position">
              <div className=" mt-4 mini-divisor-coupon-green" />
            </div>
            <div
              className={
                customLoader === true
                  ? "divisor-coupon-loader mt-4 mb-3"
                  : "divisor-coupon-green-1 mt-4 mb-3 "
              }
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default NewCoupon;
