import React from "react";

import "../../style/global.css";

function Button({
  text = "Botão",
  typeButton = "primary",
  onClick,
  style = "mb-2 col-12 col-xs-6 col-md-4 col-lg-4 col-xl-4 text-center scale-in-center",
  isDisabled = false,
}) {
  return (
    <div className="col row mt-3">
      {isDisabled === false ? (
        <div className="col">
          <button
            className={`btn button-${typeButton}-appito ${style}`}
            type="button"
            onClick={onClick}
          >
            {text}
          </button>
        </div>
      ) : (
        <div className="col">
          <button
            className={`btn button-${typeButton}-appito ${style}`}
            type="button"
            disabled
          >
            {text}
          </button>
        </div>
      )}
    </div>
  );
}

export default Button;
