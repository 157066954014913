import React, { useState, useEffect } from "react";

import "./style.css";

import iconWhatsapp from "../../assets/icons/booking-contract/whatsapp.svg";
import iconWhatsappRed from "../../assets/icons/booking-contract/whatsapp_red.svg";
import iconMail from "../../assets/icons/booking-contract/mail.svg";
import iconMailRed from "../../assets/icons/booking-contract/mail_red.svg";
function BookingContractList({ contract, onClickCheck }) {
  useEffect(() => {}, []);

  const validateCheck = (actualBilling) => {
    let validate = false;

    switch (actualBilling) {
      case "CA":
        validate = true;
        break;
      case "PF":
        validate = true;
        break;
      case "FI":
        validate = true;
        break;
      case "PM":
        validate = true;
        break;
      case "MP":
        validate = false;
        break;
      case "ML":
        validate = false;
        break;
      case "PA":
        validate = true;
        break;
      case "SE":
        validate = false;
        break;
      case "SP":
        validate = false;
        break;
      case "SL":
        validate = false;
        break;
      default:
        break;
    }

    return validate;
  };
  return (
    <div className="check">
      <div className="check-width">
        {validateCheck(contract.actualBilling) === true ? (
          <div>
            <input
              style={{
                border: "2px solid #7B7E75",
                borderRadius: "2px",
              }}
              className="form-check-input"
              type="checkbox"
              value=""
              id={contract.bookingId}
              disabled
            />
          </div>
        ) : (
          <div>
            <input
              style={{
                backgroundColor: contract.picked ? "#173016 " : "",

                border: "2px solid #494C45",
                borderRadius: "2px",
              }}
              className="form-check-input "
              type="checkbox"
              value=""
              checked={contract.picked}
              id={contract.bookingId}
              onChange={() => onClickCheck()}
            />
          </div>
        )}
      </div>

      <div className="B2B-flag">
        <div className="text-start d-flex">
          <div className="text-truncate">{contract.name}</div>
          {contract.isB2B ? (
            <span
              className={`B2B-text text-center ${
                contract.isB2B === true
                  ? "statusGrey componentBadge "
                  : "d-none"
              } `}
            >
              B2B
            </span>
          ) : (
            ""
          )}
        </div>
      </div>

      <div className="default-column">
        <p className="col-12  text-start">
          <div className="text-truncate">{contract.dayTime}</div>
        </p>
      </div>
      <div className="default-column">
        <p className="col-12 text-start">
          <div className="text-truncate">{contract.price}</div>
        </p>
      </div>

      {/* BADGES LASTBILLING */}
      <div className="badges-billing-column">
        <div className="col text-center d-flex ">
          <h5
            className={`text-center ${
              contract.lastBilling === "NS"
                ? "statusGrey componentBadge "
                : "d-none"
            } `}
          >
            Não enviado
          </h5>
          <h5
            className={`text-center ${
              contract.lastBilling === "CA"
                ? "statusRed componentBadge "
                : "d-none"
            } `}
          >
            Cancelado
          </h5>
          <h5
            className={`text-center ${
              contract.lastBilling === "PE"
                ? "BCS-status-PE componentBadge "
                : "d-none"
            } `}
          >
            Pendente
          </h5>
          <h5
            className={`text-center ${
              contract.lastBilling === "PF"
                ? "BCS-status-PF componentBadge "
                : "d-none"
            } `}
          >
            Pago (F)
          </h5>
          <h5
            className={`text-center ${
              contract.lastBilling === "FI"
                ? "BCS-status-PE componentBadge "
                : "d-none"
            } `}
          >
            Financeiro
          </h5>
          <h5
            className={`text-center ${
              contract.lastBilling === "PM"
                ? "BCS-status-PM componentBadge "
                : "d-none"
            } `}
          >
            Pago (M)
          </h5>
          <h5
            className={`text-center ${
              contract.lastBilling === "PA"
                ? "BCS-status-PA componentBadge "
                : "d-none"
            } `}
          >
            Pago
          </h5>
        </div>
      </div>
      {/* FIM BADGES LASTBILLING */}
      {/* BADGES ACTUALBILLING */}

      <div className="badges-actual-billing-column">
        <div className="col text-center d-flex ">
          <h5
            className={`text-center ${
              contract.actualBilling === "NS"
                ? "statusGrey componentBadge "
                : "d-none"
            } `}
          >
            Não enviado
          </h5>
          <h5
            className={`text-center ${
              contract.actualBilling === "CA"
                ? "statusRed componentBadge "
                : "d-none"
            } `}
          >
            Cancelado
          </h5>
          <h5
            className={`text-center ${
              contract.actualBilling === "PF"
                ? "BCS-status-PF componentBadge "
                : "d-none"
            } `}
          >
            Pago (F)
          </h5>
          <h5
            className={`text-center ${
              contract.actualBilling === "PM"
                ? "BCS-status-PM componentBadge "
                : "d-none"
            } `}
          >
            Pago (M)
          </h5>
          <h5
            className={`text-center ${
              contract.actualBilling === "MS"
                ? "BCS-status-MS componentBadge "
                : "d-none"
            } `}
          >
            Enviado (M)
            <img src={iconMail} alt="logo" />
            <img src={iconWhatsapp} alt="logo" />
          </h5>
          <h5
            className={`text-center ${
              contract.actualBilling === "MP"
                ? "BCS-status-MS componentBadge "
                : "d-none"
            } `}
          >
            Enviado
            <img src={iconMailRed} alt="logo" />
            <img src={iconWhatsapp} alt="logo" />
          </h5>
          <h5
            className={`text-center ${
              contract.actualBilling === "ML"
                ? "BCS-status-MS componentBadge "
                : "d-none"
            } `}
          >
            Enviado
            <img src={iconMail} alt="logo" />
            <img src={iconWhatsappRed} alt="logo" />
          </h5>
          <h5
            className={`text-center ${
              contract.actualBilling === "VE"
                ? "BCS-status-VE componentBadge "
                : "d-none"
            } `}
          >
            Vencido
          </h5>
          <h5
            className={`text-center ${
              contract.actualBilling === "PA"
                ? "BCS-status-PA componentBadge "
                : "d-none"
            } `}
          >
            Pago
          </h5>
          <h5
            className={`text-center ${
              contract.actualBilling === "SE"
                ? "BCS-status-SE componentBadge "
                : "d-none"
            } `}
          >
            Enviado
            <img src={iconMail} alt="logo" />
            <img src={iconWhatsapp} alt="logo" />
          </h5>
          <h5
            className={`text-center ${
              contract.actualBilling === "SP"
                ? "BCS-status-SE componentBadge "
                : "d-none"
            } `}
          >
            Enviado
            <img src={iconMailRed} alt="logo" />
            <img src={iconWhatsapp} alt="logo" />
          </h5>
          <h5
            className={`text-center ${
              contract.actualBilling === "SL"
                ? "BCS-status-SE componentBadge "
                : "d-none"
            } `}
          >
            Enviado
            <img src={iconMail} alt="logo" />
            <img src={iconWhatsappRed} alt="logo" />
          </h5>
        </div>
      </div>
      {/* FIM BADGES ACTUALBILLING */}
      <div className="BCS-divisor-grey" />
    </div>
  );
}

export default BookingContractList;
